import React, {createContext, useEffect, useState} from "react";
import "./RatesModal.scss";
import AppButton from "../../../../layout/AppButton/AppButton";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  getTariffsListAsync,
  multipleUpdateTariffListAsync,
} from "../../../../../store/reducers/dispatcher/catalog";
import { getDistrictListAsync } from "../../../../../store/reducers/common/catalog";
import { appListPerPage } from "../../../../../utils/constants";
import { getDispatcherProfileAsync } from "../../../../../store/reducers/dispatcher/profile";
import {
  convertPrice,
} from "../../../../../scripts/commonScripts";
import RatesItem from "./components/RatesItem";

export const RatesModalContext = createContext();

const RatesModal = ({ options, closeModal }) => {
  const dispatch = useDispatch();
  const { tariffsList } = useSelector((state) => state.dispatcherCatalog);
  const { dispatcherProfile } = useSelector((store) => store.dispatcherProfile);

  const [selectedDistrict, setSelectedDistrict] = useState(
    options?.options?.id
  );
  const [filteredTariffs, setFilteredTariffs] = useState([]);
  const [tariffs, setTariffs] = useState([]);
  const [isAdmin, setIsAdmin] = useState(dispatcherProfile?.is_admin);

  useEffect(() => {
    dispatch(getTariffsListAsync());
    dispatch(getDispatcherProfileAsync());
    setIsAdmin(dispatcherProfile?.is_admin);
  }, []);

  useEffect(() => {
    setSelectedDistrict(options?.options);
    const arr = [];
    for (const tariff of tariffsList) {
      if (tariff?.district?.name === selectedDistrict?.name) {
        arr.push(
          Object.assign(
            {},
            { ...tariff, price: convertPrice(tariff?.price, "front") }
          )
        );
      }
    }
    setFilteredTariffs(arr);
  }, [options.options]);

  function updateTariffs() {
    const tariffsBackArr = [];
    for (const item of tariffs) {
      tariffsBackArr.push({
        ...item,
        price: convertPrice(item?.price, "back"),
      });
    }
    setTimeout(() => {
      dispatch(multipleUpdateTariffListAsync(tariffsBackArr));
    }, 0);
    setTimeout(() => {
      dispatch(getTariffsListAsync());
      dispatch(
        getDistrictListAsync({
          page: options?.options?.page,
          page_size: appListPerPage,
        })
      );
    }, 500);
    setTimeout(() => {
      closeModal();
    }, 1500);
  }

  function setFilteredTariffsCheck(isChecked, index) {
    const tariff = {
      id: filteredTariffs[index].id,
      active: isChecked,
      price: !isChecked ? null : filteredTariffs[index]?.price,
    };
    setFilteredTariffs(() => {
      filteredTariffs[index].price =
        !isChecked || !!filteredTariffs[index].price
          ? null
          : filteredTariffs[index].price;
      filteredTariffs[index].active = isChecked;
      return filteredTariffs;
    });
    setTariffs((value) => {
      const arr = [...value];
      if (!!arr.find((item) => item.id === tariff.id)) {
        return arr.filter((item) => item.id !== tariff.id);
      } else {
        arr.push(tariff);
        return arr;
      }
    });
  }
  function setTariffsData(index, price) {
    const tariff = {
      id: filteredTariffs[index].id,
      active: filteredTariffs[index].active,
      price: price,
    };

    setFilteredTariffs(() => {
      filteredTariffs[index].price = price;
      filteredTariffs[index].active = !(
        filteredTariffs[index]?.price?.length === 1 ||
        filteredTariffs[index]?.price?.length === undefined
      );
      return filteredTariffs;
    });
    setTariffs(() => {
      const arr = [...tariffs];
      const itemInArr =
        arr.filter((item) => item.id === filteredTariffs[index].id)[0] ||
        tariff;
      const itemInArrIndex = arr.indexOf(itemInArr);
      if (!!arr.find((item) => item.id === tariff.id)) {
        for (const item of arr) {
          if (item.id === tariff.id) {
            item.price = price;
            item.active = !!item.price;
          }
        }
        return arr;
      } else {
        arr.splice(itemInArrIndex, 0, { ...itemInArr, price });
        return arr;
      }
    });
  }

  return (
    <div className="content">
      <h1 className="modal-name">{`${selectedDistrict?.id?.name}`}</h1>
      <div className="rates-wrapper">
        <div className="district-top-line">
          <h4 className="district-item-name">Доступность</h4>
          <div className="rates-second-line">
            <h4 className="district-item-name">Услуга</h4>
            <h4 className="district-item-name">Единицы измерения</h4>
            <h4 className="district-item-name">Цена на услугу</h4>
          </div>
        </div>
        <RatesModalContext.Provider
          value={{
            setFilteredTariffsCheck,
            isAdmin,
            setTariffsData,
          }}
        >
          {filteredTariffs?.map((el, index) => {
            return <RatesItem el={el} index={index} />;
          })}
        </RatesModalContext.Provider>
      </div>
      <AppButton onClick={() => (isAdmin ? updateTariffs() : closeModal())}>
        {isAdmin ? `Сохранить` : `Закрыть`} тарифы
      </AppButton>
    </div>
  );
};

RatesModal.defaultProps = {
  closeModal: () => {},
  options: {},
};

RatesModal.propTypes = {
  closeModal: PropTypes.func,
  options: PropTypes.object,
};

export default RatesModal;
