import React from "react";
import { RouterPath, routerPathModes } from "./utils/utils";
import RoutesWrapper from "./components/layout/AppLayout/RoutesWrapper";
import store from "./store";
import { Provider } from "react-redux";

const App = () => {
  const routes = [
    { path: RouterPath.auth, isShowNavbar: false, mode: routerPathModes.auth },
    {
      path: RouterPath.empList,
      isShowNavbar: true,
      mode: routerPathModes.empList,
    },
    { path: RouterPath.apps, isShowNavbar: true, mode: routerPathModes.apps },
    {
      path: RouterPath.account,
      isShowNavbar: true,
      mode: routerPathModes.account,
    },
    {
      path: RouterPath.typesOfServices,
      isShowNavbar: true,
      mode: routerPathModes.typesOfServ,
    },
    { path: RouterPath.map, isShowNavbar: true, mode: routerPathModes.map },
    {
      path: RouterPath.urList,
      isShowNavbar: true,
      mode: routerPathModes.urList,
    },
    {
      path: RouterPath.areas,
      isShowNavbar: true,
      mode: routerPathModes.areas,
    },
    { path: "*", isShowNavbar: false, mode: routerPathModes.error },
  ];

  return (
    <Provider store={store}>
      <RoutesWrapper routes={routes} />
    </Provider>
  );
};

export default App;
