import React, { useEffect, useState } from "react";
import "../../styles/ServAddModal.scoped.scss";
import AppInput from "../../../../layout/AppInput/AppInput";
import AppButton from "../../../../layout/AppButton/AppButton";
import AppSelect from "../../../../layout/AppSelect/AppSelect";
import { uid } from "uid";
import EventEmitter from "../../../../../utils/EventEmitter";
import {useDispatch, useSelector} from "react-redux";
import { addError } from "../../../../../store/reducers/alerts";
import PropTypes from "prop-types";
import AppCheckbox from "../../../../layout/AppCheckbox/AppCheckbox";
import {getDispatcherProfileAsync} from "../../../../../store/reducers/dispatcher/profile";

const ServAddModal = ({ options, closeModal }) => {
  const dispatch = useDispatch();
  const { dispatcherProfile } = useSelector((store) => store.dispatcherProfile);
  const { dictionaries } = useSelector((store) => store.commonCatalog);

  const [id, setId] = useState(0);
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [customerTypes, setCustomerTypes] = useState([]);
  const [measure, setMeasure] = useState("");
  const [times, setTimes] = useState([]);
  const [priceType, setPriceType] = useState("");
  const [destination, setDestination] = useState("");
  let [count, setCount] = useState(0);
  const [active, setActive] = useState(true);
  const [isAdmin, setIsAdmin] = useState(localStorage?.getItem('isAdmin'));

  const [quantity1, setQuantity1] = useState(options?.option?.quantities[0]);
  const [quantity2, setQuantity2] = useState(options?.option?.quantities[1]);
  const [quantity3, setQuantity3] = useState(options?.option?.quantities[2]);
  const [quantity4, setQuantity4] = useState(options?.option?.quantities[3]);
  const [quantity5, setQuantity5] = useState(options?.option?.quantities[4]);
  const [quantity6, setQuantity6] = useState(options?.option?.quantities[5]);
  const [quantity7, setQuantity7] = useState(options?.option?.quantities[6]);
  const [quantity8, setQuantity8] = useState(options?.option?.quantities[7]);
  const [quantity9, setQuantity9] = useState(options?.option?.quantities[8]);
  const [quantity10, setQuantity10] = useState(options?.option?.quantities[9]);

  const destinationList = [
    { id: "address", name: "Адрес" },
    { id: "route", name: "Маршрут" },
  ];

  const measureList = [
    { id: "volume", name: "Объем, m3" },
    { id: "time", name: "Часы" },
    { id: "weight", name: "Вес, тонны" },
    { id: "barrel", name: "Бочка" },
  ];

  const typesList = [
    { id: "water", name: "Вода" },
    { id: "waste", name: "Отходы" },
    { id: "machinery", name: "Транспорт" },
    { id: "cargo", name: "Доставка" },
  ];

  const priceTypeList = [
    { id: "volume", name: "Объем (1 м3)" },
    { id: "passage", name: "Рейс" },
    { id: "time", name: "Время (1 час)" },
    { id: "weight_dist", name: "Вес/расстояние (1 тн./км)" },
    { id: "barrel", name: "Бочка (200 л.)" },
  ];

  const timesList = [
    { id: "10", name: "10:00" },
    { id: "11", name: "11:00" },
    { id: "12", name: "12:00" },
    { id: "14", name: "14:00" },
    { id: "15", name: "15:00" },
    { id: "16", name: "16:00" },
    { id: "17", name: "17:00" },
    { id: "10-13", name: "с 10:00 до 13:00" },
    { id: "14-18", name: "с 14:00 до 18:00" },
  ];

  const customerTypesList = [
    { id: "individual", name: "Физическое лицо" },
    { id: "company", name: "Предприятие" },
  ];

  useEffect(() => {
    dispatch(getDispatcherProfileAsync());
    setIsAdmin(dispatcherProfile?.is_admin);
  }, [])

  useEffect(() => {
    if (options?.option) {
      const {
        id,
        name,
        type,
        quantities,
        measure,
        destination,
        times,
        customer_types,
        price_type,
      } = options?.option;
      setName(name);
      setType(type);
      setMeasure(measure);
      setDestination(destination);
      setCustomerTypes(customer_types);
      setPriceType(price_type);
      setTimes(times);
      setId(id);
      if (!!quantities[0]) setQuantity1(quantities[0]);
      if (!!quantities[1]) {
        setQuantity2(quantities[1]);
        setCount(1);
      }
      if (!!quantities[2]) {
        setQuantity3(quantities[2]);
        setCount(2);
      }
      if (!!quantities[3]) {
        setQuantity4(quantities[3]);
        setCount(3);
      }
      if (!!quantities[4]) {
        setQuantity5(quantities[4]);
        setCount(4);
      }
      if (!!quantities[5]) {
        setQuantity6(quantities[5]);
        setCount(5);
      }
      if (!!quantities[6]) {
        setQuantity7(quantities[6]);
        setCount(6);
      }
      if (!!quantities[7]) {
        setQuantity8(quantities[7]);
        setCount(7);
      }
      if (!!quantities[8]) {
        setQuantity9(quantities[8]);
        setCount(8);
      }
      if (!!quantities[9]) {
        setQuantity10(quantities[9]);
        setCount(9);
      }
    }
  }, [options?.option]);

  function addService() {
      const quantities = [];
      if (!!quantity1?.length) quantities.push(quantity1);
      if (!!quantity2?.length) quantities.push(quantity2);
      if (!!quantity3?.length) quantities.push(quantity3);
      if (!!quantity4?.length) quantities.push(quantity4);
      if (!!quantity5?.length) quantities.push(quantity5);
      if (!!quantity6?.length) quantities.push(quantity6);
      if (!!quantity7?.length) quantities.push(quantity7);
      if (!!quantity8?.length) quantities.push(quantity8);
      if (!!quantity9?.length) quantities.push(quantity9);
      if (!!quantity10?.length) quantities.push(quantity10);
    if (name && type && !!times?.length && measure && destination && priceType && customerTypes && !!quantities?.length) {
      const data = {
        id: uid(),
        name,
        type,
        times,
        measure,
        destination,
        price_type: priceType,
        quantities,
        customer_types: customerTypes,
      };
      EventEmitter.emit("addNewService", data);
      closeModal();
    } else {
      dispatch(addError("Ошибка добавления услуги! Не все поля заполнены!"));
    }
  }

  function editService() {
    if (name && type && !!times?.length && measure && destination && priceType && customerTypes) {
      const quantities = [];
      if (!!quantity1 || quantities[0]) quantities.push(quantity1 || quantities[0]);
      if (!!quantity2 || quantities[1]) quantities.push(quantity2 || quantities[1]);
      if (!!quantity3 || quantities[2]) quantities.push(quantity3 || quantities[2]);
      if (!!quantity4 || quantities[3]) quantities.push(quantity4 || quantities[3]);
      if (!!quantity5 || quantities[4]) quantities.push(quantity5 || quantities[4]);
      if (!!quantity6 || quantities[5]) quantities.push(quantity6 || quantities[5]);
      if (!!quantity7 || quantities[6]) quantities.push(quantity7 || quantities[6]);
      if (!!quantity8 || quantities[7]) quantities.push(quantity8 || quantities[7]);
      if (!!quantity9 || quantities[8]) quantities.push(quantity9 || quantities[8]);
      if (!!quantity10 || quantities[9]) quantities.push(quantity10 || quantities[9]);
      const data = {
        id,
        name,
        type,
        times,
        measure,
        destination,
        price_type: priceType,
        quantities,
        customer_types: customerTypes,
        active: active,
      }
      EventEmitter.emit("editService", data);
      closeModal();
    } else {
      dispatch(
        addError("Ошибка редактирования услуги! Не все поля заполнены!")
      );
    }
  }

  function toggleData(item, type) {
    if (type === "customerTypes") {
      setCustomerTypes((prevSelected) => {
        const newArr = [...prevSelected];
        if (!!newArr.find((el) => el === item)) {
          return newArr.filter((el) => el !== item);
        } else {
          newArr.push(item);
          return newArr;
        }
      });
    }
    if (type === "time") {
      setTimes((prevSelected) => {
        const newArr = [...prevSelected];
        if (!!newArr.find((el) => el === item)) {
          return newArr.filter((el) => el !== item);
        } else {
          newArr.push(item);
          return newArr;
        }
      });
    }
  }

  function findInTypesList(type) {
    return typesList.filter((e) => e.id === type)[0];
  }

  function findInMeasureList(measure) {
    return measureList.filter((e) => e?.id === measure)[0];
  }

  function findInDestinationList(destination) {
    return destinationList.filter((e) => e?.id === destination)[0];
  }

  function findInPriceType() {
    return priceTypeList.filter((e) => e?.id === priceType)[0];
  }

  function findInCustomerTypesList(type) {
    const itemArr = [];
    for (const item of customerTypes) {
      itemArr.push(customerTypesList.filter((e) => e?.id === item)[0]);
    }
    return !!itemArr.filter((e) => e?.id === type)[0];
  }

  function findInTimesList(time) {
    const itemsArr = [];
    for (const item of times) {
      itemsArr.push(timesList.filter((e) => e?.id === item)[0]);
    }
    return !!itemsArr.filter((e) => e?.id === time)[0];
  }

  function increaseCount() {
    setCount((count += 1));
  }

  function modalName () {
    if (!!isAdmin) {
      if (options?.option?.name) return "Изменение услуги"
      else return "Добавление услуги"
    } else return "Просмотр услуги"
  }

  function modalBtnAction () {
    if (!!isAdmin) {
      if (options?.option?.name) return editService()
      else return addService()
    } else return closeModal()
  }

  function modalBtnText () {
    if (!!isAdmin) {
      if (options?.option?.name) return "Редактировать услугу"
      else return "Добавить услугу"
    } else return "Закрыть услугу"
  }

  return (
    <>
      <div className="content">
        <h1 className="modal-name">
          {modalName()}
        </h1>
        <div className="input-area">
          <AppInput
            value={name}
            className="modal-input"
            label="Наименование услуги"
            placeholder="Указать"
            validations={{ isEmpty: true }}
            onInput={(e) => setName(e)}
            isDisabled={!isAdmin}
            maxLength={30}
            isReq={true}
          />
          <AppSelect
            optionsList={typesList}
            defaultText={"Укажите категорию"}
            label="Категория услуги"
            mode="grey"
            selected={findInTypesList(type)}
            onInput={(e) => setType(e)}
            isDisabled={!isAdmin}
            isReq={true}
          />
          <div className='line-type'>Тип покупателя<div className="req">*</div></div>
          <AppCheckbox
            label="Физическое лицо"
            onChange={() => toggleData("individual", "customerTypes")}
            checked={findInCustomerTypesList("individual")}
            isDisabled={!isAdmin}
          />
          <AppCheckbox
            label="Юридическое лицо"
            onChange={() => toggleData("company", "customerTypes")}
            checked={findInCustomerTypesList("company")}
            isDisabled={!isAdmin}
          />
          <AppSelect
            label="Тип цены"
            optionsList={priceTypeList}
            selected={findInPriceType()}
            onInput={(e) => setPriceType(e)}
            isDisabled={!isAdmin}
            isReq={true}
          />
          <AppSelect
            optionsList={measureList}
            label="Единицы измерения"
            selected={findInMeasureList(measure)}
            onInput={(e) => setMeasure(e)}
            isDisabled={!isAdmin}
            isReq={true}
          />
          <AppInput
            placeholder="Введите количество"
            label="Вариант количества 1"
            onInput={setQuantity1}
            value={quantity1}
            type="number"
            isDisabled={!isAdmin}
            isReq={true}
          />
          {count > 0 && (
            <AppInput
              placeholder="Введите количество"
              label="Вариант количества 2"
              onInput={setQuantity2}
              value={quantity2}
              type="number"
              isDisabled={!isAdmin}
            />
          )}
          {count > 1 && (
            <AppInput
              placeholder="Введите количество"
              label="Вариант количества 3"
              onInput={setQuantity3}
              value={quantity3}
              type="number"
              isDisabled={!isAdmin}
            />
          )}
          {count > 2 && (
            <AppInput
              placeholder="Введите количество"
              label="Вариант количества 4"
              onInput={setQuantity4}
              value={quantity4}
              type="number"
              isDisabled={!isAdmin}
            />
          )}
          {count > 3 && (
            <AppInput
              placeholder="Введите количество"
              label="Вариант количества 5"
              onInput={setQuantity5}
              value={quantity5}
              type="number"
              isDisabled={!isAdmin}
            />
          )}
          {count > 4 && (
            <AppInput
              placeholder="Введите количество"
              label="Вариант количества 6"
              onInput={setQuantity6}
              value={quantity6}
              type="number"
              isDisabled={!isAdmin}
            />
          )}
          {count > 5 && (
            <AppInput
              placeholder="Введите количество"
              label="Вариант количества 7"
              onInput={setQuantity7}
              value={quantity7}
              type="number"
              isDisabled={!isAdmin}
            />
          )}
          {count > 6 && (
            <AppInput
              placeholder="Введите количество"
              label="Вариант количества 8"
              onInput={setQuantity8}
              value={quantity8}
              type="number"
              isDisabled={!isAdmin}
            />
          )}
          {count > 7 && (
            <AppInput
              placeholder="Введите количество"
              label="Вариант количества 9"
              onInput={setQuantity9}
              value={quantity9}
              type="number"
              isDisabled={!isAdmin}
            />
          )}
          {count > 8 && (
            <AppInput
              placeholder="Введите количество"
              label="Вариант количества 10"
              onInput={setQuantity10}
              value={quantity10}
              type="number"
              isDisabled={!isAdmin}
            />
          )}
          {!!isAdmin && count !== 9 && (
            <AppButton
              isDisabled={
                (count === 0 && !quantity1) ||
                (count === 1 && !quantity2) ||
                (count === 2 && !quantity3) ||
                (count === 3 && !quantity4) ||
                (count === 4 && !quantity5) ||
                (count === 5 && !quantity6) ||
                (count === 6 && !quantity7) ||
                (count === 7 && !quantity8) ||
                (count === 8 && !quantity9) ||
                (count === 9 && !quantity10)
              }
              onClick={() => increaseCount()}
            >
              Добавить еще
            </AppButton>
          )}
          <div className='line-type'>Время для заказа<div className="req">*</div></div>
          <AppCheckbox
            label="10:00"
            onChange={() => toggleData("10", "time")}
            checked={findInTimesList("10")}
            isDisabled={!isAdmin}
          />
          <AppCheckbox
            label="11:00"
            onChange={() => toggleData("11", "time")}
            checked={findInTimesList("11")}
            isDisabled={!isAdmin}
          />
          <AppCheckbox
            label="12:00"
            onChange={() => toggleData("12", "time")}
            checked={findInTimesList("12")}
            isDisabled={!isAdmin}
          />
          <AppCheckbox
            label="14:00"
            onChange={() => toggleData("14", "time")}
            checked={findInTimesList("14")}
            isDisabled={!isAdmin}
          />
          <AppCheckbox
            label="15:00"
            onChange={() => toggleData("15", "time")}
            checked={findInTimesList("15")}
            isDisabled={!isAdmin}
          />
          <AppCheckbox
            label="16:00"
            onChange={() => toggleData("16", "time")}
            checked={findInTimesList("16")}
            isDisabled={!isAdmin}
          />
          <AppCheckbox
            label="17:00"
            onChange={() => toggleData("17", "time")}
            checked={findInTimesList("17")}
            isDisabled={!isAdmin}
          />
          <AppCheckbox
            label="с 10:00 до 13:00"
            onChange={() => toggleData("10-13", "time")}
            checked={findInTimesList("10-13")}
            isDisabled={!isAdmin}
          />
          <AppCheckbox
            label="с 14:00 до 18:00"
            onChange={() => toggleData("14-18", "time")}
            checked={findInTimesList("14-18")}
            isDisabled={!isAdmin}
          />
          <AppSelect
            optionsList={destinationList}
            selected={findInDestinationList(destination)}
            onInput={setDestination}
            label="Тип адреса"
            isDisabled={!isAdmin}
            isReq={true}
          />
          {!!isAdmin && (
              <AppCheckbox label="Активная услуга?" value={active} checked={active} onChange={() => setActive(!active) } />
          )}
        </div>
        <AppButton onClick={modalBtnAction}>
          {modalBtnText()}
        </AppButton>
      </div>
    </>
  );
};

ServAddModal.defaultProps = {
  closeModal: () => {},
  options: {},
};

ServAddModal.propTypes = {
  closeModal: PropTypes.func,
  options: PropTypes.object,
};

export default ServAddModal;
