import { createSlice } from "@reduxjs/toolkit";
import api from "../../api";
import { addSuccess } from "../alerts";
import { APIUrlPrefix } from "../../../utils/constants";
import { errorHandler } from "../../../scripts/commonScripts";

const dispatcherAccountSlice = createSlice({
  name: "dispatcherAccount",
  initialState: {
    dispatchers: [],
    dispatchersCount: 0,
    dispatcher: {},
    executors: [],
    executorsCount: 0,
    executorData: {},
    companyCustomerList: [],
    companyCustomerListCount: 0,
    companyCustomer: {},
    executor: {},
    dispatcherCustomerAddressList: [],
    executorsLocationsList: [],
    currentExecutorsOrdersList: [],
  },
  reducers: {
    clearExecutor: (state, action) => {
      state.executor = null;
    },
    setDispatchersList: (state, action) => {
      state.dispatchers = [...action.payload];
    },
    setDispatchersListCount: (state, action) => {
      state.dispatchersCount = action.payload;
    },
    setDispatcherById: (state, action) => {
      state.dispatcher = action.payload;
    },
    setExecutorsList: (state, action) => {
      state.executors = [...action.payload];
    },
    setExecutorsListCount: (state, action) => {
      state.executorsCount = action.payload;
    },
    setExecutorById: (state, action) => {
      state.executor = action.payload;
    },
    setCompanyCustomerList: (state, action) => {
      state.companyCustomerList = [...action.payload];
    },
    setCompanyCustomerListCount: (state, action) => {
      state.companyCustomerListCount = action.payload;
    },
    setCompanyCustomer: (state, action) => {
      state.companyCustomer = action.payload;
    },
    setDispatcherCustomerAddressList: (state, action) => {
      state.dispatcherCustomerAddressList = [...action.payload];
    },
    setExecutorsLocationsList: (state, action) => {
      state.executorsLocationsList = [...action.payload];
    },
    setCurrentExecutorOrdersById: (state, action) => {
      state.currentExecutorsOrdersList = [...action.payload];
    },
  },
});

export const getDispatchersListAsync = (params) => async (dispatch) => {
  await api
    .get(`${APIUrlPrefix}/dispatcher/account/dispatcher/`, { params: params })
    .then((r) => {
      dispatch(setDispatchersListCount(r?.data?.count));
      dispatch(setDispatchersList(r?.data?.results));
    })
    .catch((e) => {
      errorHandler({
        error: e,
        dispatch,
        text: "",
        requestName: "getDispatchersListAsync",
      });
    });
};

export const createDispatcherAsync = (data) => async (dispatch) => {
  await api
    .post(`${APIUrlPrefix}/dispatcher/account/dispatcher/`, data)
    .then(() => {
      dispatch(addSuccess("Диспетчер успешно добавлен!"));
    })
    .catch((e) => {
      errorHandler({
        error: e,
        dispatch,
        text: "Ошибка добавления диспетчера!",
        requestName: "createDispatcherAsync",
      });
    });
};

export const getDispatcherByIdAsync = (id) => async (dispatch) => {
  await api
    .get(`${APIUrlPrefix}/dispatcher/account/dispatcher/${id}/`)
    .then((r) => {
      dispatch(setDispatcherById(r?.data));
    })
    .catch((e) => {
      errorHandler({
        error: e,
        dispatch,
        text: "",
        requestName: "getDispatcherByIdAsync",
      });
    });
};

export const editDispatcherByIdAsync = (id, data) => async (dispatch) => {
  await api
    .patch(`${APIUrlPrefix}/dispatcher/account/dispatcher/${id}/`, data)
    .then((r) => {
      dispatch(addSuccess("Диспетчер успешно отредактирован!"));
      dispatch(setDispatcherById(r?.data));
    })
    .catch((e) => {
      errorHandler({
        error: e,
        dispatch,
        text: "Ошибка редактирования диспетчера!",
        requestName: "editDispatcherByIdAsync",
      });
    });
};

export const getExecutorsListAsync = (params) => async (dispatch) => {
  await api
    .get(`${APIUrlPrefix}/dispatcher/account/executor/`, { params: params })
    .then((r) => {
      dispatch(setExecutorsList(r?.data?.results));
      dispatch(setExecutorsListCount(r?.data?.count));
    })
    .catch((e) => {
      errorHandler({
        error: e,
        dispatch,
        text: "",
        requestName: "getExecutorsListAsync",
      });
    });
};

export const createExecutorAsync = (data) => async (dispatch) => {
  await api
    .post(`${APIUrlPrefix}/dispatcher/account/executor/`, data)
    .then(() => {
      dispatch(addSuccess("Сотрудник успешно добавлен!"));
    })
    .catch((e) => {
      errorHandler({
        error: e,
        dispatch,
        text: "Ошибка добавления сотрудника!",
        requestName: "createExecutorAsync",
      });
    });
};

export const getExecutorByIdAsync = (id) => async (dispatch) => {
  await api
    .get(`${APIUrlPrefix}/dispatcher/account/executor/${id}/`)
    .then((r) => {
      dispatch(setExecutorById(r?.data));
    })
    .catch((e) => {
      errorHandler({
        error: e,
        dispatch,
        text: "",
        requestName: "getExecutorByIdAsync",
      });
    });
};

export const editExecutorDyIdAsync = (id, data) => async (dispatch) => {
  await api
    .patch(`${APIUrlPrefix}/dispatcher/account/executor/${id}/`, data)
    .then((r) => {
      dispatch(addSuccess("Сотрудник успешно отредактирован!"));
      dispatch(setExecutorById(r?.data));
    })
    .catch((e) => {
      errorHandler({
        error: e,
        dispatch,
        text: "Ошибка редактирования сотрудника!",
        requestName: "editExecutorDyIdAsync",
      });
    });
};

export const getCompanyCustomerListAsync = (params) => async (dispatch) => {
  await api
    .get(`${APIUrlPrefix}/dispatcher/account/company_customer/`, {
      params: params,
    })
    .then((r) => {
      dispatch(setCompanyCustomerList(r?.data?.results));
      dispatch(setCompanyCustomerListCount(r?.data?.count));
      return r?.data?.results;
    })
    .catch((e) => {
      errorHandler({
        error: e,
        dispatch,
        text: "",
        requestName: "getCompanyCustomerListAsync",
      });
    });
};

export const createCompanyCustomerAsync = (data) => async (dispatch) => {
  await api
    .post(`${APIUrlPrefix}/dispatcher/account/company_customer/`, data)
    .then(() => {
      dispatch(addSuccess("Компания успешно добавлена!"));
    })
    .catch((e) => {
      errorHandler({
        error: e,
        dispatch,
        text: "Ошибка добавления компании!",
        requestName: "createCompanyCustomerAsync",
      });
    });
};

export const getCompanyCustomerByIdAsync =
  (CompanyCustomerId) => async (dispatch) => {
    await api
      .get(
        `${APIUrlPrefix}/dispatcher/account/company_customer/${CompanyCustomerId}/`
      )
      .then((r) => {
        dispatch(setCompanyCustomer(r?.data));
      })
      .catch((e) => {
        errorHandler({
          error: e,
          dispatch,
          text: "",
          requestName: "getCompanyCustomerByIdAsync",
        });
      });
  };

export const editCompanyCustomerByIdAsync =
  (CompanyCustomerId, data) => async (dispatch) => {
    await api
      .patch(
        `${APIUrlPrefix}/dispatcher/account/company_customer/${CompanyCustomerId}/`,
        data
      )
      .then((r) => {
        dispatch(setCompanyCustomer(r?.data));
        dispatch(addSuccess("Компания успешно отредактирована!"));
      })
      .catch((e) => {
        errorHandler({
          error: e,
          dispatch,
          text: "Ошибка редактирования компании!",
          requestName: "editCompanyCustomerByIdAsync",
        });
      });
  };

export const getDispatcherCustomerAddressListAsync =
  (params) => async (dispatch) => {
    await api
      .get(`${APIUrlPrefix}/dispatcher/account/address/`, { params })
      .then((r) => {
        dispatch(setDispatcherCustomerAddressList(r?.data));
      })
      .catch((e) => {
        errorHandler({
          error: e,
          dispatch,
          text: "",
          requestName: "getDispatcherCustomerAddressListAsync",
        });
      });
  };

export const getDispatcherCustomerAddressesByIdAsync =
  (id, params) => async (dispatch) => {
    await api
      .get(`${APIUrlPrefix}/dispatcher/account/address/${id}`, {
        params: params,
      })
      .then((r) => {
        console.log("r.data getDispatcherCustomerAddressesByIdAsync", r?.data);
      })
      .catch((e) => {
        errorHandler({
          error: e,
          dispatch,
          text: "",
          requestName: "getDispatcherCustomerAddressesByIdAsync",
        });
      });
  };

export const createDispatcherCustomerAddressAsync =
  (data) => async (dispatch) => {
    await api
      .post(`${APIUrlPrefix}/dispatcher/account/address/`, data)
      .then(() => {
        dispatch(addSuccess("Адрес успешно добавлен!"));
      })
      .catch((e) => {
        errorHandler({
          error: e,
          dispatch,
          text: "Ошибка добавления адреса!",
          requestName: "createDispatcherCustomerAddressAsync",
        });
      });
  };

export const editDispatcherCustomerAddressAsync =
  (data, id) => async (dispatch) => {
    await api
      .patch(`${APIUrlPrefix}/dispatcher/account/address/${id}/`, data)
      .then(() => {
        dispatch(addSuccess("Адрес успешно изменен!"));
      })
      .catch((e) => {
        errorHandler({
          error: e,
          dispatch,
          text: "Ошибка изменения адреса!",
          requestName: "editDispatcherCustomerAddressAsync",
        });
      });
  };

export const getExecutorsLocationsListAsync = () => async (dispatch) => {
  await api
    .get(`${APIUrlPrefix}/dispatcher/account/executor/location/`)
    .then((r) => {
      dispatch(setExecutorsLocationsList(r?.data));
    })
    .catch((e) => {
      errorHandler({
        error: e,
        dispatch,
        text: "",
        requestName: "getExecutorsLocationsListAsync",
      });
    });
};

export const getCurrentExecutorOrdersByIdAsync = (id) => async (dispatch) => {
  await api
    .get(`${APIUrlPrefix}/dispatcher/account/executor/${id}/order/`)
    .then((r) => {
      dispatch(setCurrentExecutorOrdersById(r?.data));
    })
    .catch((e) => {
      errorHandler({
        error: e,
        dispatch,
        text: "",
        requestName: "getCurrentExecutorOrdersByIdAsync",
      });
    });
};

export const dispatcher = (state) => state.dispatcherAccount.dispatcher;
export const executor = (state) => state.dispatcherAccount.executorData;

export const {
  setCompanyCustomer,
  setCompanyCustomerList,
  setCompanyCustomerListCount,
  setDispatchersListCount,
  setDispatchersList,
  setDispatcherById,
  setExecutorsList,
  setExecutorsListCount,
  setExecutorById,
  setDispatcherCustomerAddressList,
  setExecutorsLocationsList,
  setCurrentExecutorOrdersById,
  clearExecutor,
} = dispatcherAccountSlice.actions;
export default dispatcherAccountSlice.reducer;
