import React, { useContext } from "react";
import AppInput from "../../../../../../layout/AppInput/AppInput";
import ApplicationProcessModalPaymentIndication from "../ApplicationProcessModalPaymentIndication";
import AppTextarea from "../../../../../../layout/AppTextarea/AppTextarea";
import AppButton from "../../../../../../layout/AppButton/AppButton";
import "../../../../styles/ApplicationModal.scoped.scss";
import {
  convertPrice,
  convertQuantities,
  orderHistoryActionFormat,
  statusFormat,
  timeFormat,
  timesName,
} from "../../../../../../../scripts/commonScripts";
import { AppProcessModalContext } from "../../ApplicationProcessModal";

const ApplicationProcessModalStoryMode = () => {
  const {
    setModalView,
    paid,
    dispatcherOrderHistory: {
      dispatcher,
      new_data,
      old_data,
      action,
      created_at,
    },
    service: { measure },
  } = useContext(AppProcessModalContext);

  return (
    <>
      <div className="container">
        <h1 className="story-modal-name">История</h1>
        <div className="story-input-area">
          <AppInput
            className="modal-input"
            validations={{ isEmpty: true }}
            label="Действие"
            placeholder="Без изменений"
            isDisabled="true"
            value={orderHistoryActionFormat(action)}
            mode="white"
          />
          <AppInput
            className="modal-input"
            validations={{ isEmpty: true }}
            label="Диспетчер"
            placeholder="Без изменений"
            isDisabled="true"
            value={dispatcher?.fio}
            mode="white"
          />
          <AppInput
            className="modal-input"
            validations={{ isEmpty: true }}
            label="Дата и время изменений"
            placeholder="Без изменений"
            isDisabled="true"
            value={timeFormat(created_at)}
            mode="white"
          />
        </div>
        <span className="bottom-line"></span>
        <div className="story-blocks">
          <div className="story-block-area">
            <h1 className="story-side-name">Информация до изменения</h1>
            <AppInput
              className="modal-input"
              validations={{ isEmpty: true }}
              label="Статус"
              placeholder="Без изменений"
              isDisabled="true"
              mode="white"
              value={statusFormat(old_data?.status)}
            />
            <AppInput
              className="modal-input"
              validations={{ isEmpty: true }}
              label="Исполнитель"
              placeholder="Без изменений"
              isDisabled="true"
              mode="white"
              value={old_data?.executor?.fio || "Не выбран"}
            />
            <AppInput
              className="modal-input"
              validations={{ isEmpty: true }}
              label="Итоговое кол-во"
              placeholder="Без изменений"
              isDisabled="true"
              mode="white"
              value={convertQuantities([old_data?.quantity], measure)[0]?.name}
            />
            <AppInput
              className="modal-input"
              validations={{ isEmpty: true }}
              label="Стоимость"
              placeholder="Без изменений"
              isDisabled="true"
              mode="white"
              value={old_data?.price}
            />
            <ApplicationProcessModalPaymentIndication paid={paid} />
            <div className="date-story-form">
              <AppInput
                className="app-date-input"
                validations={{ isEmpty: true }}
                label="Желаемая дата"
                placeholder="01.01.2022"
                mode="white"
                isDisabled="true"
                value={old_data?.date}
              />
              <AppInput
                className="app-date-input"
                validations={{ isEmpty: true }}
                label="Время"
                placeholder="00:00"
                mode="white"
                isDisabled="true"
                value={timesName(old_data?.time)}
              />
            </div>
            <div className="comment-area">
              <AppTextarea
                label="Комментарий"
                validations={{ isEmpty: true }}
                isDisabled="true"
                mode="white"
                value={old_data?.dispatcher_comment || "Не указан"}
              />
            </div>
          </div>
          <div className="story-block-area">
            <h1 className="story-side-name">Информация после изменений</h1>
            <AppInput
              className="modal-input"
              validations={{ isEmpty: true }}
              label="Статус"
              placeholder="Без изменений"
              isDisabled="true"
              mode="white"
              value={statusFormat(new_data?.status)}
            />
            <AppInput
              className="modal-input"
              validations={{ isEmpty: true }}
              label="Исполнитель"
              placeholder="Без изменений"
              isDisabled="true"
              mode="white"
              value={new_data?.executor?.fio || "Не выбран"}
            />
            <AppInput
              className="modal-input"
              validations={{ isEmpty: true }}
              label="Итоговое кол-во"
              placeholder="Без изменений"
              isDisabled="true"
              mode="white"
              value={convertQuantities([new_data?.quantity], measure)[0]?.name}
            />
            <AppInput
              className="modal-input"
              validations={{ isEmpty: true }}
              label="Стоимость"
              placeholder="Без изменений"
              isDisabled="true"
              mode="white"
              value={convertPrice(new_data?.price, 'front')}
            />
            <ApplicationProcessModalPaymentIndication paid={paid} />
            <div className="date-story-form">
              <AppInput
                className="app-date-input"
                validations={{ isEmpty: true }}
                label="Желаемая дата"
                placeholder="01.01.2022"
                mode="white"
                isDisabled="true"
                value={new_data?.date}
              />
              <AppInput
                className="app-date-input"
                validations={{ isEmpty: true }}
                label="Время"
                placeholder="00:00"
                mode="white"
                isDisabled="true"
                value={timesName(new_data?.time)}
              />
            </div>
            <div className="comment-area">
              <AppTextarea
                label="Комментарий"
                validations={{ isEmpty: true }}
                isDisabled="true"
                mode="white"
                value={new_data?.dispatcher_comment || "Не указан"}
              />
            </div>
          </div>
        </div>
        <AppButton onClick={() => setModalView("app")}>
          Назад к заявке
        </AppButton>
      </div>
    </>
  );
};

export default ApplicationProcessModalStoryMode;
