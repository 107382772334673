import React, {useLayoutEffect} from "react";
import { uid } from "uid";
import PropTypes from "prop-types";
import {
  servicesCategoryHuman,
  servicesSearchById,
} from "../../../../scripts/table";
import "../../../../components/pages/types-of-services/styles/ServAddModal.scoped.scss";

const ServTableFragment = ({ openModal, option, idx, servicesList, }) => {
  useLayoutEffect(() => {
    servicesSearchById({
      services: option?.services,
      servicesList,
      setterFunc: () => {},
    });
  }, [option?.services, servicesList]);

  return (
    <React.Fragment key={idx}>
      <div className="d-td d-td__number" onClick={() => openModal(option, idx)}>
        {option?.name}
      </div>
      <div className="d-td d-td__number" onClick={() => openModal(option, idx)}>
        {servicesCategoryHuman(option?.type)}
      </div>
    </React.Fragment>
  );
};

ServTableFragment.defaultProps = {
  openModal: () => {},
  option: {},
  idx: uid(),
};
ServTableFragment.propTypes = {
  openModal: PropTypes.func,
  option: PropTypes.object,
  idx: PropTypes.number,
};

export default ServTableFragment;
