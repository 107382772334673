import React from 'react';
import ReactDOM from 'react-dom/client';
import './components/styles/index.scss';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import {BrowserTracing} from "@sentry/tracing";
import * as Sentry from "@sentry/react";

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: "https://a745f1b922e54a71bb17185b4a10fd7e@o4504479038898176.ingest.sentry.io/4504479041716224",
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter>
          <App />
      </BrowserRouter>
  </React.StrictMode>
);

