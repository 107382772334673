import React, {useEffect, useLayoutEffect, useState} from "react";
import { uid } from "uid";
import PropTypes from "prop-types";
import {
    servicesCategoryHuman,
    servicesSearchById,
} from "../../../../scripts/table";
import AppButton from "../../AppButton/AppButton";
import "../../../../components/pages/types-of-services/styles/ServAddModal.scoped.scss";
import { showModal } from "../../../../store/reducers/modals";
import { modalKeys } from "../../../../utils/utils";
import {useDispatch, useSelector} from "react-redux";
import {getDispatcherProfileAsync} from "../../../../store/reducers/dispatcher/profile";
import '../../../styles/Tables.scss';

const AreasTableFragment = ({ openModal, option, idx, servicesList, page }) => {
    const dispatch = useDispatch();
    const { dispatcherProfile } = useSelector((store) => store.dispatcherProfile);

    const [isAdmin, setIsAdmin] = useState(localStorage?.getItem('isAdmin'));

    useLayoutEffect(() => {
        servicesSearchById({
            services: option?.services,
            servicesList,
            setterFunc: () => {},
        });
    }, [option?.services, servicesList]);

    useEffect(() => {
        if (dispatcherProfile) {
            localStorage.setItem('isAdmin', dispatcherProfile?.is_admin)
        }
        dispatch(getDispatcherProfileAsync());
        setIsAdmin(localStorage.getItem('isAdmin'));
    }, [])

    function openRatesModal(id) {
        dispatch(showModal({ key: modalKeys.RatesModal, options: {id, page} }));
    }

    return (
        <React.Fragment key={idx}>
            <div className="d-td d-td__number" onClick={() => openModal(option, idx)}>
                {option?.name}
            </div>
            <div className="d-td d-td__number" onClick={() => openModal(option, idx)}>
                {servicesCategoryHuman(option?.type)}
            </div>
            <div className="d-td">
                <div className="button-area">
                    <AppButton mode="blue" className="button-area__button" onClick={() => openRatesModal(option)}>
                        {isAdmin ? 'Редактировать' : 'Посмотреть'}
                    </AppButton>
                </div>
            </div>
        </React.Fragment>
    );
};

AreasTableFragment.defaultProps = {
    openModal: () => {},
    option: {},
    idx: uid(),
};
AreasTableFragment.propTypes = {
    openModal: PropTypes.func,
    option: PropTypes.object,
    idx: PropTypes.number,
};

export default AreasTableFragment;
