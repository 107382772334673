import React, { useEffect, useState } from "react";
import { empListFilters } from "../../../../utils/utils";
import { uid } from "uid";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { servicesSearchById } from "../../../../scripts/table";
import { EmpTypeConverter } from "../../../../scripts/commonScripts";

const EmpListTableFragment = ({ openModal, option, idx }) => {
  const { servicesList } = useSelector((store) => store.commonCatalog);
  const [serviceObjArr, setServiceObjArr] = useState([]);
  const indicatorStyles = classNames(indicatorStylesModes());

  useEffect(() => {
    servicesSearchById({
      services: option?.services,
      servicesList,
      setterFunc: (value) => setServiceObjArr(value),
    });
  }, [option?.services, servicesList]);

  // useEffect(() => {
  //   console.log(option);
  // });
  function indicatorStylesModes() {
    if (option.status === "order") return "table__indicator-status_green";
    if (option.status === "missing") return "table__indicator-status_red";
    if (option.status === "working")
      return "table__indicator-status_green-outline";
  }

  return (
    <React.Fragment key={idx}>
      <div className="d-td d-td__work" onClick={() => openModal(option, idx)}>
        <div className="fio">
          <img
            className="fio__avatar"
            src={
              option.filePreview ||
              option.photo ||
              require("../../../images/logo512.png")
            }
            alt={option.fio}
          />
          <div className="fio__container">
            <div className="fio__name">{option.fio}</div>
            <div className="fio__email">{option.email}</div>
          </div>
        </div>
      </div>
      <div className="d-td d-td__phone" onClick={() => openModal(option, idx)}>
        {option.phone}
      </div>
      {empListFilters.executors === "Исполнители" && (
        <>
          <div
            className="d-td d-td__work"
            onClick={() => openModal(option, idx)}
          >
            {/*<div className="table__types">*/}
            {/*  {!!serviceObjArr?.length && localStorage.getItem('empListFilter') !== 'Диспетчеры' &&*/}
            {/*    serviceObjArr.map((service, idx) => {*/}
            {/*      return (*/}
            {/*        <div className="table__types-type" key={idx}>*/}
            {/*          {service.name}*/}
            {/*        </div>*/}
            {/*      );*/}
            {/*    })}*/}
            {/*  {!serviceObjArr?.length && localStorage.getItem('empListFilter') !== 'Диспетчеры' && <div>Идет загрузка данных...</div>}*/}
            {/*</div>*/}
            <div
              className="d-td d-td__phone"
              onClick={() => openModal(option, idx)}
            >
              {EmpTypeConverter(option.type)}
            </div>
          </div>
          <div
            className="d-td d-td__work"
            onClick={() => openModal(option, idx)}
          >
            <div className="table__indicator-status">
              <div className={indicatorStyles} />
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

EmpListTableFragment.defaultProps = {
  openModal: () => {},
  option: {},
  idx: uid(),
};
EmpListTableFragment.propTypes = {
  openModal: PropTypes.func,
  option: PropTypes.object,
  idx: PropTypes.number,
};

export default EmpListTableFragment;
