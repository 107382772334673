import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./styles/areas-styles.scss";
import AppMainTable from "../../layout/AppMainTable/AppMainTable";
import { getTariffsListAsync } from "../../../store/reducers/dispatcher/catalog";
import { getDistrictListAsync } from "../../../store/reducers/common/catalog";
import {appListPerPage, areasPerPage} from "../../../utils/constants";
import EventEmitter from "../../../utils/EventEmitter";

const Areas = () => {
  const dispatch = useDispatch();

  const headerList = useSelector((state) => state.tables.areasHeaders);
  const { districtList, districtListCount } = useSelector((state) => state.commonCatalog);

  const [itemsList, setItemList] = useState(districtList);
  const [districtListCountData, setDistrictListCountData] = useState(districtListCount);
  const [pageData, setPageData] = useState(1);

  useEffect(() => {
    dispatch(getTariffsListAsync());
    dispatch(getDistrictListAsync({ page: pageData, page_size: areasPerPage }));
  }, []);

  useEffect(() => {
      setItemList(districtList);
  }, [districtList]);

  useEffect(() => {
      setDistrictListCountData(districtListCount);
  }, [districtListCountData])

  useEffect(() => {
    const pageListener = EventEmitter.addListener("page", (page) => {
      setPageData(page);
      dispatch(
          getDistrictListAsync({
            page: page,
            page_size: appListPerPage,
          })
      );
    });
    return () => {
      pageListener.remove()
    }
  })

  return (
    <AppMainTable
      title="Тарифы"
      itemList={itemsList}
      itemListTotalCount={districtListCountData}
      headerList={headerList}
      perPage={appListPerPage}
      mode="areasTable"
    />
  );
};

export default Areas;
