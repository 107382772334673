import React from "react";
import "../styles/ApplicationProcessModalCardEmp.scoped.scss";
import testImg from "../../../../../images/logo.svg";
import classNames from "classnames";
import PropTypes from "prop-types";
import AppButton from "../../../../../layout/AppButton/AppButton";
import SvgSelector from "../../../../../layout/SvgSelector/SvgSelector";
import { EmpTypeConverter } from "../../../../../../scripts/commonScripts";
const ApplicationProcessModalCardEmp = ({
  setModalView,
  mode,
  executor,
  selectExecutor,
  isShowStatus,
  status,
  isShowSelect,
  isShowDDIcon,
  isOpened,
  filteredExecutors0,
  setFilteredExecutors0,
  filteredExecutors1,
  setFilteredExecutors1,
  arrNumber,
}) => {
  const cardClass = classNames("card-emp", cardClassModes());
  const empStatusClass = classNames("card-emp__status", indicatorStylesModes());
  const arrowStyles = classNames("select-arrow", flipArrow());

  function flipArrow() {
    if (isOpened || executor?.selected) return "select-arrow-up";
  }

  function cardClassModes() {
    if (mode?.includes("with-border")) return "card-emp_with-border";
    else return "";
  }

  function indicatorStylesModes() {
    if (status === "order") return "card-emp__status_green";
    if (status === "missing") return "card-emp__status_red";
    if (status === "working") return "card-emp__status_green-outline";
  }

  function setSelectedExecutor() {
    let index0 = null;
    let index1 = null;
    let arr0 = [...filteredExecutors0];
    let arr1 = [...filteredExecutors1];
    if (arrNumber === 0) {
      for (let i = 0; i < arr0.length; i += 1) {
        if (arr0[i].id === executor.id) index0 = i;
      }
      setFilteredExecutors0(() => {
        for (const item of arr0)
          if (arr0[index0]?.id !== item?.id) item.selected = false;
        arr0[index0].selected = !arr0[index0].selected;
        return arr0;
      });
      setFilteredExecutors1(() => {
        for (const item of arr1) item.selected = false;
        return arr1;
      });
    }
    if (arrNumber === 1) {
      for (let i = 0; i < arr1.length; i += 1) {
        if (arr1[i].id === executor.id) index1 = i;
      }
      setFilteredExecutors1(() => {
        for (const item of arr1)
          if (arr1[index1]?.id !== item?.id) item.selected = false;
        arr1[index1].selected = !arr1[index1].selected;
        return arr1;
      });
      setFilteredExecutors0(() => {
        for (const item of arr0) item.selected = false;
        return arr0;
      });
    }
  }

  return (
    <div className={cardClass} onClick={() => setSelectedExecutor()}>
      <div className="stat-icon-wrapper">
        {isShowStatus && <div className={empStatusClass} />}
      </div>
      <img
        className="card-emp__img"
        src={executor?.img || executor?.photo || testImg}
        alt=""
      />
      <div className="card-emp__container">
        <div className="card-emp__container-name">
          {executor?.name || executor?.fio}
        </div>
        <div className="card-emp__container-email">{executor?.phone}</div>
        <div className="card-emp__container-email">{executor?.email}</div>
      </div>
      {!!executor?.current_orders?.length && (
        <div className="card-emp__phone">
          <div className="card-emp__phone">Исполняет:</div>
          {executor?.current_orders[0].service?.name}
        </div>
      )}
      {!executor?.current_orders?.length && (
        <div className="card-emp__phone">
          {EmpTypeConverter(executor?.type)}
        </div>
      )}
      {isShowSelect && (
        <div className="button-area">
          <AppButton
            mode="blue"
            onClick={(e) => {
              e.stopPropagation();
              selectExecutor(executor);
              setModalView("app");
            }}
          >
            Выбрать
          </AppButton>
        </div>
      )}
      {isShowDDIcon && (
        <div className={arrowStyles}>
          <SvgSelector id="select-arrow-down" />
        </div>
      )}
    </div>
  );
};

ApplicationProcessModalCardEmp.defaultProps = {
  isShowSelect: true,
  mode: "",
  executor: {
    name: "Не указано",
    email: "Не указано",
    phone: "Не указано",
  },
  selectExecutor: () => {},
  isShowStatus: false,
};
ApplicationProcessModalCardEmp.propTypes = {
  isShowSelect: PropTypes.bool,
  mode: PropTypes.string,
  executor: PropTypes.object,
  selectExecutor: PropTypes.func,
  isShowStatus: PropTypes.bool,
};

export default ApplicationProcessModalCardEmp;
