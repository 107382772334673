import React, { useEffect } from "react";
import "./styles/NavbarCardBottom.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  dispatcherProfile,
  getDispatcherProfileAsync,
} from "../../../../../store/reducers/dispatcher/profile";
import defPhoto from "./images/logo512.png";

const NavbarCardBottom = () => {
  const dispatch = useDispatch();
  const profile = useSelector(dispatcherProfile);

  function isAdmin() {
    if (profile.is_admin) return "Администратор";
    else return "Диспетчер";
  }

  useEffect(() => {
    dispatch(getDispatcherProfileAsync());
  }, [dispatch]);

  return (
    <div className="navbar-card-bottom">
      <img
        className="navbar-card-bottom__avatar"
        src={!!profile.photo ? profile.photo : defPhoto}
        alt=""
      />
      <div className="navbar-card-bottom__name">
        <div className="navbar-card-bottom__name-name">{profile.fio}</div>
        <div className="navbar-card-bottom__name-work">{isAdmin()}</div>
      </div>
    </div>
  );
};

export default NavbarCardBottom;
