import React from "react";
import AppButton from "../../../../../../../../layout/AppButton/AppButton";

const AppProcessModalControlBtns = ({
  statusData,
  setStatusData,
  closeModal,
  editDispatcherOrderById,
}) => {
  const renderControlButtons = () => {
    if (statusData === "wait_approve") {
      return (
        <>
          <AppButton
            mode="blue"
            onClick={() => {
              setStatusData("approved");
            }}
          >
            Одобрить заявку
          </AppButton>
          <AppButton
            mode="red"
            onClick={() => {
              setStatusData("canceled");
            }}
          >
            Отклонить
          </AppButton>
        </>
      );
    }
    if (statusData === "approved") {
      return (
        <AppButton
          mode="blue"
          onClick={() => editDispatcherOrderById({ status: statusData })}
        >
          Сохранить изменения
        </AppButton>
      );
    }
    if (statusData === "work_is_done") {
      return (
        <>
          <AppButton
            mode="blue"
            onClick={() => {
              setStatusData("finished");
            }}
          >
            Завершить заявку
          </AppButton>
        </>
      );
    }
  };

  return (
    <>
      <div className="control-area">
        {renderControlButtons()}
        <AppButton onClick={closeModal}>Закрыть</AppButton>
      </div>
    </>
  );
};

export default AppProcessModalControlBtns;
