export const AppAlertsTimeouts = {
    success: 2000,
    error: 100000,
    info: 2000
}
export const AxiosTimeout = 25000
export const APIUrlPrefix = 'api/v1'
export const APIYMaps = {
    enterprise: false,
    preload: false,
    lang: 'ru_RU',
    mode: 'debug'
}

export const itemsPerPagePaginator = 10;
export const empListPerPage = 9;
export const urListPerPage = 10;
export const appListPerPage = 9;
export const financesPerPage = 10;
export const typeOfServPerPage = 9;
export const areasPerPage = 9;

export const updatePagesPeriod = 30 * 1000
