import { modalKeys } from "../utils/utils";
import LogoutModal from "../components/layout/Navbar/components/logoutModal/LogoutModal";
import FilterModal from "../components/layout/FilterModal/FilterModal";
import ExecutorAddModal from "../components/pages/emp-list/components/ExecutorAddModal";
import DispatcherAddModal from "../components/pages/emp-list/components/DispatcherAddModal";
import PayModal from "../components/pages/account/components/pay-modal/PayModal";
import ApplicationProcessModal from "../components/pages/applications/components/applicationProcessModal/ApplicationProcessModal";
import ServAddModal from "../components/pages/types-of-services/components/addServModal/ServAddModal";
import ExecutorAccModal from "../components/pages/emp-list/components/ExecutorAccModal";
import ChoiceServ from "../components/pages/types-of-services/components/addServModal/ChoiseServ";
import ChoiceModal from "../components/pages/emp-list/components/ChoiceModal";
import React from "react";
import Auth from "../components/pages/auth/Auth";
import ProtectedRoute from "../components/layout/AppLayout/ProtectedRoute";
import EmpList from "../components/pages/emp-list/EmpList";
import Applications from "../components/pages/applications/Applications";
import Account from "../components/pages/account/Account";
import TypesOfServices from "../components/pages/types-of-services/TypesOfServices";
import MapPage from "../components/pages/map/MapPage";
import ErrorPage from "../components/pages/error-page/ErrorPage";
import UrList from "../components/pages/ur-list/UrList";
import UrModalAdd from "../components/pages/ur-list/components/UrModalAdd";
import IPModalAdd from "../components/pages/ur-list/components/IPModalAdd";
import ChoiceUrModal from "../components/pages/ur-list/components/ChoiseUrModal";
import MapModal from "../components/pages/map/components/mapModal/MapModal";
import ApplicationNewModal from "../components/pages/applications/components/applicationNewModal/ApplicationNewModal";
import RatesModal from "../components/pages/areas/components/RatesModal/RatesModal";
import Areas from "../components/pages/areas/Areas";

export function modalRender(options, closeModal, currentModalKey) {
  const modals = {
    [modalKeys.logoutModal]: (
      <LogoutModal options={options} closeModal={closeModal} />
    ),
    [modalKeys.filterModal]: (
      <FilterModal options={options} closeModal={closeModal} />
    ),
    [modalKeys.executorAddModal]: (
      <ExecutorAddModal options={options} closeModal={closeModal} />
    ),
    [modalKeys.dispatcherAddModal]: (
      <DispatcherAddModal options={options} closeModal={closeModal} />
    ),
    [modalKeys.payModal]: (
      <PayModal options={options} closeModal={closeModal} />
    ),
    [modalKeys.applicationProcessModal]: (
      <ApplicationProcessModal options={options} closeModal={closeModal} />
    ),
    [modalKeys.applicationNewModal]: (
      <ApplicationNewModal options={options} closeModal={closeModal} />
    ),
    [modalKeys.servAddModal]: (
      <ServAddModal options={options} closeModal={closeModal} />
    ),
    [modalKeys.executorAccModal]: (
      <ExecutorAccModal options={options} closeModal={closeModal} />
    ),
    [modalKeys.choiceServ]: (
      <ChoiceServ options={options} closeModal={closeModal} />
    ),
    [modalKeys.choiceModal]: (
      <ChoiceModal options={options} closeModal={closeModal} />
    ),
    [modalKeys.urModalAdd]: (
      <UrModalAdd options={options} closeModal={closeModal} />
    ),
    [modalKeys.IPModalAdd]: (
      <IPModalAdd options={options} closeModal={closeModal} />
    ),
    [modalKeys.choiceUrModal]: (
      <ChoiceUrModal options={options} closeModal={closeModal} />
    ),
    [modalKeys.mapModal]: (
      <MapModal options={options} closeModal={closeModal} />
    ),
    [modalKeys.RatesModal]: (
        <RatesModal options={options} closeModal={closeModal} />
    ),

  };
  return modals[currentModalKey];
}

export function checkRoute(mode) {
  const modes = {
    auth: <Auth />,
    empList: (
      <ProtectedRoute>
        <EmpList />
      </ProtectedRoute>
    ),
    apps: (
      <ProtectedRoute>
        <Applications />
      </ProtectedRoute>
    ),
    account: (
      <ProtectedRoute>
        <Account />
      </ProtectedRoute>
    ),
    typesOfServ: (
      <ProtectedRoute>
        <TypesOfServices />
      </ProtectedRoute>
    ),
    map: (
      <ProtectedRoute>
        <MapPage />
      </ProtectedRoute>
    ),
    urList: (
      <ProtectedRoute>
        <UrList />
      </ProtectedRoute>
    ),
    areas: (
      <ProtectedRoute>
        <Areas />
      </ProtectedRoute>
    ),
    error: <ErrorPage />,
  };
  return modes[mode];
}
