import React, {useContext, useEffect, useState} from "react";
import AppSelect from "../../../../../../../layout/AppSelect/AppSelect";
import AppTextarea from "../../../../../../../layout/AppTextarea/AppTextarea";
import AppPhoneInput from "../../../../../../../layout/AppPhoneInput/AppPhoneInput";
import AppInput from "../../../../../../../layout/AppInput/AppInput";
import AppButton from "../../../../../../../layout/AppButton/AppButton";
import ApplicationProcessModalPaymentIndication from "../../ApplicationProcessModalPaymentIndication";
import SvgSelector from "../../../../../../../layout/SvgSelector/SvgSelector";
import { useDispatch, useSelector } from "react-redux";
import {
  createDispatcherCustomerAddressAsync,
  editDispatcherCustomerAddressAsync,
  getCompanyCustomerListAsync,
  getDispatcherCustomerAddressListAsync,
  getExecutorByIdAsync,
} from "../../../../../../../../store/reducers/dispatcher/account";
import AppProcessModalControlBtns from "./components/AppProcessModalControlBtns";
import {
  convertDateToDot,
  customerTypeId,
  customerTypeOdjApp,
  orderHistoryActionFormat,
  timeFormat,
} from "../../../../../../../../scripts/commonScripts";
import "../../../../../styles/ApplicationModal.scoped.scss";
import ApplicationSelectExecutor from "../../../../ApplicationSelectExecutor";
import ApplicationNewModalAddressSelector from "../../../../applicationNewModal/components/modes/ApplicationNewModalAppMode/components/ApplicationNewModalAddressSelector";
import { getDistrictListAsync } from "../../../../../../../../store/reducers/common/catalog";
import { getDispatcherProfileAsync } from "../../../../../../../../store/reducers/dispatcher/profile";
import { getDiscountListAsync } from "../../../../../../../../store/reducers/dispatcher/catalog";
import {AppProcessModalContext} from "../../../ApplicationProcessModal";

const ApplicationProcessModalAppMode = () => {
  const dispatch = useDispatch();

  const {
    district,
    setDistrict,
    setDistrictId,
    personStatus,
    customer,
    servicesList,
    service,
    setService,
    setQuantityData,
    organizationsData,
    setModalView,
    dispatcherOrder,
    priceData,
    setPriceData,
    paid,
    dispatcherComment,
    customer_comment,
    executor_comment,
    executor,
    executorId,
    setDispatcherComment,
    setDateData,
    setTimeData,
    customer_type,
    statusData,
    setStatusData,
    closeModal,
    setOrganizationId,
    order_history,
    setOrderHistoryId,
    organizationId,
    quantitiesList,
    currentQuantity,
    currentDate,
    timesList,
    currentTime,
    setAddress,
    address,
    setAddressRoute1,
    addressRoute1,
    setAddressRoute2,
    addressRoute2,
    customerId,
    customerType,
    editDispatcherOrderById,
  } = useContext(AppProcessModalContext);

  const executorFullData = useSelector(
    (state) => state.dispatcherAccount.executor
  );
  const { districtList } = useSelector((state) => state.commonCatalog);
  const { dispatcherProfile } = useSelector((state) => state.dispatcherProfile);

  const [newAddress, setNewAddress] = useState("");
  const [newAddressComment, setNewAddressComment] = useState("");

  const statuses = [
    { id: "created", name: "Создано" },
    { id: "canceled", name: "Отклонено" },
    { id: "wait_approve", name: "Обработка" },
    { id: "approved", name: "Подтверждено диспетчером" },
    { id: "in_progress", name: "В работе" },
    { id: "work_is_done", name: "Работа выполнена" },
    { id: "wait_payment", name: "Ожидает оплаты" },
    { id: "finished", name: "Завершено" },
  ];

  function setHistoryId(id) {
    setOrderHistoryId(id);
    setModalView("story");
  }

  useEffect(() => {
    if (executorId?.id) {
      dispatch(getExecutorByIdAsync(executorId?.id));
    }
  }, [executorId?.id]);

  useEffect(() => {
    if (executor?.id) {
      dispatch(getExecutorByIdAsync(executor?.id));
      dispatch(getDiscountListAsync());
    }
  }, [executor?.id]);

  function StatusesVarsArr(current) {
    const arr = [current];
    if (["wait_approve", "approved"].includes(statusData))
      arr.push({ id: "canceled", name: "Отклонено" });
    if (["work_is_done", "wait_payment"].includes(statusData))
      arr.push({ id: "finished", name: "Завершено" });
    return arr;
  }

  function createDispatcherCustomerAddress() {
    dispatch(
      createDispatcherCustomerAddressAsync({
        content_type: customerType,
        object_id: customerId,
        address: newAddress,
        comment: newAddressComment,
      })
    ).then(() => {
      setTimeout(() => {
        dispatch(
          getDispatcherCustomerAddressListAsync({
            content_type: customerType,
            object_id: customerId,
          })
        );
      }, 500);
    });
    setNewAddress("");
    setNewAddressComment("");
  }

  function editDispatcherCustomerAddress() {
    dispatch(
      editDispatcherCustomerAddressAsync(
        {
          content_type: customerType,
          object_id: customerId,
          address: newAddress,
          comment: newAddressComment,
        },
        address
      )
    ).then(() => {
      setTimeout(() => {
        dispatch(
          getDispatcherCustomerAddressListAsync({
            content_type: customerType,
            object_id: customerId,
          })
        );
      }, 500);
    });
    setNewAddress("");
    setNewAddressComment("");
  }

  useEffect(() => {
    if (customerType && customerId) {
      dispatch(
        getCompanyCustomerListAsync({
          page: 1,
          page_size: 15000,
          type: customerTypeId(customerType),
        })
      );
      dispatch(
        getDispatcherCustomerAddressListAsync({
          content_type: customerType,
          object_id: customerId,
        })
      );
    }
  }, [customerType, customerId]);

  useEffect(() => {
    dispatch(getDistrictListAsync());
    dispatch(getDispatcherProfileAsync());
  }, []);

  useEffect(() => {
    setPriceData(priceData)
  }, [priceData])
  return (
    <>
      <div className="container">
        <h2 className="modal-name">Обработка заявки</h2>
        <div className="app-info">
          <div className="left-side">
            <h3 className="side-name">Заявитель</h3>
            <div className="person-info">
              <AppSelect
                label="Статус"
                optionsList={personStatus}
                defaultText="Указать тип"
                selected={customerTypeOdjApp(customer_type)}
                isDisabled={true}
                mode="white"
                isReq={true}
              />
              <AppInput
                label="№ плательщика"
                placeholder="Номер плательщика"
                validations={{ isEmpty: true }}
                value={customer?.user_id}
                isDisabled={true}
                mode="white"
                isReq={true}
              />
              <AppTextarea
                label="ФИО"
                value={customer?.ceo_fio || customer?.fio}
                placeholder="Введите данные"
                isDisabled={true}
                rows={2}
                mode="white"
                isReq={true}
              />
              <AppPhoneInput
                placeholder="Введите номер телефона"
                validations={{ isEmpty: true, minLength: 18 }}
                label="Телефон"
                isDisabled={true}
                value={customer?.phone}
                mode="white"
                isReq={true}
              />
              <AppInput
                label="e-mail"
                validations={{ isEmpty: true }}
                isDisabled={true}
                value={customer?.email}
                placeholder="Не указан"
                mode="white"
                isReq={true}
              />
            </div>
            <div className="app-body">
              <h3 className="side-name">Адрес</h3>
              <div className="app-body">
                <ApplicationNewModalAddressSelector
                  statusData={statusData}
                  destination={service?.destination}
                  setAddress={setAddress}
                  address={address}
                  setAddressRoute1={setAddressRoute1}
                  addressRoute1={addressRoute1}
                  setAddressRoute2={setAddressRoute2}
                  addressRoute2={addressRoute2}
                  setNewAddress={setNewAddress}
                  newAddress={newAddress}
                  setNewAddressComment={setNewAddressComment}
                  newAddressComment={newAddressComment}
                  createDispatcherCustomerAddress={
                    createDispatcherCustomerAddress
                  }
                  editDispatcherCustomerAddress={editDispatcherCustomerAddress}
                  customerId={customerId}
                  serviceData={service}
                  customerType={customerType}
                />
              </div>
            </div>
          </div>
          <div className="middle-section">
            <h3 className="side-name">Заявка</h3>
            <div className="middle-section-wrapper">
              <AppSelect
                label="Услуга"
                optionsList={servicesList}
                selected={service}
                onInput={setService}
                defaultText="Категория"
                mode="white"
                isDisabled={true}
                isReq={true}
              />
              <AppSelect
                label="Кол-во"
                placeholder="Укажите количество"
                validations={{ isEmpty: true }}
                optionsList={quantitiesList}
                selected={currentQuantity}
                onInput={setQuantityData}
                mode="white"
                isReq={true}
              />
              <div className="date-form">
                <AppInput
                  className="app-date-input-year"
                  label="Желаемая дата"
                  placeholder="01.01.2022"
                  validations={{ isEmpty: true }}
                  type="date"
                  value={currentDate}
                  onInput={(e) => setDateData(convertDateToDot(e))}
                  mode="white"
                  isReq={true}
                />
                <AppSelect
                  optionsList={timesList}
                  defaultText="--:--"
                  label="Время"
                  validations={{ isEmpty: true }}
                  selected={currentTime}
                  onInput={setTimeData}
                  mode="white"
                  isReq={true}
                />
                <AppInput
                  label="№ заявки"
                  placeholder="Номер заявки"
                  validations={{ isEmpty: true }}
                  value={dispatcherOrder?.id}
                  isDisabled={true}
                  mode="white"
                  isReq={true}
                />
                <AppInput
                  label="Стоимость заявки (₽)"
                  placeholder="₽"
                  validations={{ isEmpty: true, isNumbersWithDot: true }}
                  value={priceData}
                  onInput={setPriceData}
                  mode="white"
                  isReq={true}
                />
                {!![
                  "approved",
                  "wait_approve",
                  "work_is_done",
                  "wait_payment",
                ].includes(statusData) && (
                  <AppSelect
                    optionsList={StatusesVarsArr(
                      statuses.filter((el) => el.id === statusData)[0]
                    )}
                    defaultText="Выберите статус заявки"
                    label="Статус заявки"
                    selected={statuses.filter((el) => el.id === statusData)[0]}
                    onInput={setStatusData}
                    mode="white"
                  />
                )}

                <AppSelect
                  optionsList={districtList}
                  defaultText="Выберите район заявки"
                  label="Район"
                  selected={district}
                  mode="white"
                  isDisabled={dispatcherProfile?.is_admin}
                  onInput={(id) => {
                    setDistrictId(id);
                    setDistrict(districtList.filter((el) => el.id === id)[0]);
                  }}
                  isReq={true}
                />
                <ApplicationProcessModalPaymentIndication paid={paid} />
                {/*TODO: Вернуть скидки*/}
                {/*<div className="discount-area">*/}
                {/*  {!!discountList?.length && (*/}
                {/*    <>*/}
                {/*      <h3 className="discount-label">Скидка</h3>*/}
                {/*      <div className="discount-variants">*/}
                {/*        {discountList.map((option, idx) => {*/}
                {/*          return (*/}
                {/*            <div className="discount-position" key={idx}>*/}
                {/*              /!*<div>{option?.name}</div>*!/*/}
                {/*              <div className="discount-wrapper">*/}
                {/*                /!*<div>{option?.percent}%</div>*!/*/}
                {/*                <AppCheckbox*/}
                {/*                  mode="blue"*/}
                {/*                  label={`${option?.name} ${option?.percent}%`}*/}
                {/*                  onChange={() => setSelectedDiscountsData({ name: option?.name, percent: option?.percent, id: option?.id})}*/}
                {/*                />*/}
                {/*              </div>*/}
                {/*            </div>*/}
                {/*          );*/}
                {/*        })}*/}
                {/*      </div>*/}
                {/*    </>*/}
                {/*  )}*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
          <div className="right-section-wrapper">
            <h3 className="side-name">Исполнитель</h3>
            <div className="right-section">
              <ApplicationSelectExecutor
                executor={executor}
                executorId={executorId}
                executorFullData={executorFullData}
                organizationsData={organizationsData}
                setOrganizationId={setOrganizationId}
                setModalView={setModalView}
                organizationId={organizationId}
              />
            </div>
          </div>
        </div>
        <span className="bottom-line"></span>
        <div className="comment-area">
          <div className="comment-item">
            <h3 className="side-bottom-name">Комментарий диспетчера</h3>
            <AppTextarea
              placeholder="Добавить комментарий"
              value={dispatcherComment}
              onInput={setDispatcherComment}
            />
          </div>
          <div className="comment-item">
            <h3 className="side-bottom-name">Комментарий заказчика</h3>
            <AppTextarea
              placeholder="Добавить комментарий"
              value={customer_comment}
              isDisabled={true}
            />
          </div>
          <div className="comment-item">
            <h3 className="side-bottom-name">Комментарий исполнителя</h3>
            <AppTextarea
              placeholder="Добавить комментарий"
              value={executor_comment}
              isDisabled={true}
            />
          </div>
        </div>
        <span className="bottom-line"></span>
        <div className="changes-area">
          {!!order_history?.length && (
            <>
              <h1 className="side-name">История изменений</h1>
              <div className="changes__wrapper">
                <div className="changes__variants">
                  {order_history.map((option, idx) => {
                    return (
                      <div className="changes__item">
                        <div className="changes__item-inner" key={idx}>
                          <div className="change-name">
                            {option?.dispatcher?.fio
                              ? option?.dispatcher?.fio
                              : "Автоматическое изменение"}
                            :
                          </div>
                          <div className="change-text">
                            {orderHistoryActionFormat(option?.action)}
                          </div>
                          <div className="change-text">
                            {timeFormat(option?.created_at)}
                          </div>
                        </div>
                        <div className="changes-actions">
                          <AppButton
                            mode="blue-gradient"
                            onClick={() => {
                              setHistoryId(option?.id);
                            }}
                          >
                            <div className="story-eye">
                              <SvgSelector id="eye-icon" />
                            </div>
                            Изучить
                          </AppButton>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>
        <AppProcessModalControlBtns
          statusData={statusData}
          closeModal={closeModal}
          setStatusData={setStatusData}
          editDispatcherOrderById={editDispatcherOrderById}
        />
      </div>
    </>
  );
};

export default ApplicationProcessModalAppMode;
