import { createSlice } from "@reduxjs/toolkit";

const tablesSlice = createSlice({
  name: "tables",
  initialState: {
    typeOfServicesHeaders: ["Название услуги", "Категория"],
    areasHeaders: ["Название услуги"],
    executorListHeaders: ["Имя", "Телефон", "Специализация", "Статус"],
    dispatcherListHeaders: ["Имя", "Телефон"],
    accountTableLegal: [
      "№",
      "Плательщик",
      "Период",
      "Статус оплаты",
      "Сумма",
      "Акт вып.работ",
      "Плат.поручение",
    ],
    accountTable: [
      "№",
      "Плательщик",
      "Период",
      "Статус оплаты",
      "Сумма",
      "Акт вып.работ",
    ],
    accountTableModalDocs: ["Название", "Бланк", "Подписанный"],
    accountTableModal: [
      "Номер заказа",
      "Дата заказа",
      "Услуга",
      "Количество",
      "Сумма",
    ],
    applicationListHeaders: [
      "№",
      "Заказчик",
      "Дата и время",
      "Район",
      "Услуга",
      "Статус",
    ],
    urListHeaders: [
      "Номер плательщика",
      "Название организации",
      "Телефон",
      "ИНН",
    ],
  },
  reducers: {},
});

export default tablesSlice.reducer;
