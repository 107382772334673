import React, { createContext, useEffect, useState } from "react";
import "../../styles/ApplicationNew.scoped.scss";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import ApplicationNewModalAppMode from "./components/modes/ApplicationNewModalAppMode/ApplicationNewModalAppMode";
import ApplicationNewModalExecutorMode from "./components/modes/ApplicationNewModalExecutorMode";
import {
    createDispatcherOrderAddressAsync,
    createDispatcherOrderAsync,
    getDispatcherOrderListAsync,
} from "../../../../../store/reducers/dispatcher/order";
import {
    clearExecutor,
    getCompanyCustomerListAsync,
    getExecutorByIdAsync,
} from "../../../../../store/reducers/dispatcher/account";
import { addError } from "../../../../../store/reducers/alerts";
import { appListPerPage } from "../../../../../utils/constants";
import { setIsClosable } from "../../../../../store/reducers/modals";
import {
    convertPrice,
    customerTypeId,
} from "../../../../../scripts/commonScripts";
import {
    getDistrictListAsync,
    getServicesListAsync,
} from "../../../../../store/reducers/common/catalog";
import { getDispatcherProfileAsync } from "../../../../../store/reducers/dispatcher/profile";

export const AppNewModalContext = createContext();

const ApplicationNewModal = ({closeModal}) => {
    const dispatch = useDispatch();

    const {organizations, discountList} = useSelector(
        (state) => state.dispatcherCatalog
    );
    const {dispatcherOrder} = useSelector((state) => state.dispatcherOrder);
    const {executor} = useSelector((state) => state.dispatcherAccount);
    const {servicesList} = useSelector((state) => state.commonCatalog);

    const [modalView, setModalView] = useState("app");
    const [organizationsData, setOrganizationsData] = useState(organizations);
    const [servicesListData, setServicesListData] = useState(servicesList);
    const [customerType, setCustomerType] = useState("");
    const [customerComment, setCustomerComment] = useState("");
    const [customerId, setCustomerId] = useState("");
    const [price, setPrice] = useState("");
    const [quantity, setQuantity] = useState("");
    const [dispatcherComment, setDispatcherComment] = useState("");
    const [time, setTime] = useState("");
    const [date, setDate] = useState("");
    const [organizationId, setOrganizationId] = useState(0);
    const [executorId, setExecutorId] = useState(0);
    const [serviceData, setServiceData] = useState(0);
    const [serviceDataId, setServiceDataId] = useState(serviceData?.id);
    const [address, setAddress] = useState([]);
    const [addressRoute1, setAddressRoute1] = useState(0);
    const [addressRoute2, setAddressRoute2] = useState(0);
    const [district, setDistrict] = useState({});
    const [districtId, setDistrictId] = useState(null);
    const [isCreated, setIsCreated] = useState(false);

    const [idIP] = useState(localStorage.getItem("ip-id"));
    const [idLegal] = useState(localStorage.getItem("legal-id"));
    const [customerTypes] = useState([
        { id: idIP, name: "ИП" },
        { id: idLegal, name: "Юр.лицо" }
    ]);

    function createDispatcherOrder() {
        const errors = [];
        if (serviceData?.destination === "address") {
            if ((Array.isArray(address) && !address?.length) || !address)
                errors.push("Не выбран адрес заявителя!")
        }
        if (serviceData?.destination === "route") {
            if (!addressRoute1 || !addressRoute2)
                errors.push("Не выбраны адреса заявителя!")
        }
        if (!customerType) errors.push("Не выбран тип заявителя!");
        if (!customerId) errors.push("Не указан id заявителя!");
        if (!executorId) errors.push("Не выбран исполнитель!");
        if (!serviceData) errors.push("Не выбрана услуга!");
        if (!price?.length) errors.push("Не указана цена!");
        if (!quantity) errors.push("Не указано количество!");
        if (!date?.length) errors.push("Не указана дата!");
        if (!!date?.length) {
            const currYear = new Date().getFullYear().toString();
            let currMonth = new Date().getMonth() + 1;
            let currDate = new Date().getDate().toString();
            const yearFromDate = date.split(".")[2];
            const monthFromDate = date.split(".")[1];
            const dayFromDate = date.split(".")[0];

            if (currMonth?.toString().length === 1) {
                currMonth = "0" + currMonth.toString();
            } else currMonth.toString();
            if (currDate?.toString().length === 1) {
                currDate = "0" + currDate.toString();
            } else currDate.toString();

           //if (currMonth !== monthFromDate) errors.push("Выберите текущий месяц");
            if((currMonth - monthFromDate !== 0) && (currMonth - monthFromDate !== -1)) errors.push("Выберите правильный месяц");
            if ((currMonth - monthFromDate === -1) && dayFromDate > currDate) errors.push("Выберите правильный месяц");
            if (yearFromDate > currYear) errors.push("Выбранный год больше текущего");
            if (yearFromDate < currYear) errors.push("Выбранный год меньше текущего");
            if (
                currDate > dayFromDate &&
                currMonth === monthFromDate &&
                currYear === yearFromDate
            ) {
                errors.push("Выбрана некорректная дата");
            }
        }
        if (!time?.length) errors.push("Не указано время!");
        if (!!errors.length) for (const error of errors) dispatch(addError(error));
        if (!errors.length) {
            setIsCreated(true);

            // асинхронная переменная, ждёт пока будет создана заявка, чтобы предоставить адресу id
            let responseAsyncOrder = {};

            let customer_type = customerTypes.filter(el => el.name === customerType)[0].id;
            dispatch(
                createDispatcherOrderAsync({
                    customer_type,
                    customer_id: customerId,
                    executor: executorId?.id,
                    service: serviceData?.id,
                    status: "approved",
                    price: convertPrice(price, "back"),
                    quantity,
                    date,
                    time,
                    district: districtId,
                    customer_comment: customerComment,
                    dispatcher_comment: dispatcherComment,
                }, (data) => responseAsyncOrder = data)
            ).then(() => {
                dispatch(
                    getDispatcherOrderListAsync({
                        page: 1,
                        page_size: appListPerPage,
                    })
                );
                localStorage.removeItem("createModalPrice");
                sessionStorage.removeItem("currCustomerNewAppId");
                sessionStorage.removeItem("quantityNewApp");
                sessionStorage.removeItem("timeNewApp");
                // closeModal();
                if (serviceData?.destination === "address") {
                    setTimeout(() => {
                        dispatch(
                            createDispatcherOrderAddressAsync({
                                order: responseAsyncOrder?.id,
                                address,
                                type: "destination",
                            })
                        );
                    }, 500)
                }
                if (serviceData?.destination !== "address") {
                    dispatch(
                        createDispatcherOrderAddressAsync({
                            /*order: dispatcherOrder?.id + 1,*/
                            order: responseAsyncOrder?.id,
                            address: addressRoute1,
                            type: "start_route",
                        })
                    );
                    dispatch(
                        createDispatcherOrderAddressAsync({
                            /*order: dispatcherOrder?.id + 1,*/
                            order: responseAsyncOrder?.id,
                            address: addressRoute2,
                            type: "end_route",
                        })
                    );
                }
                dispatch(
                    getDispatcherOrderListAsync({
                        page: 1,
                        page_size: appListPerPage,
                    })
                );
            });
        }
    }

    function setCustomerIdData(id) {
        setCustomerId(id);
    }

    useEffect(() => {
        const timeNewApp = sessionStorage.getItem("timeNewApp");
        const quantityNewApp = sessionStorage.getItem("quantityNewApp");
        const currCustomerNewAppId = sessionStorage.getItem("currCustomerNewAppId");
        if (!!quantityNewApp) setQuantity(quantityNewApp);
        if (!!timeNewApp) setTime(timeNewApp);
        if (!!currCustomerNewAppId) setCustomerId(currCustomerNewAppId);
        dispatch(setIsClosable(false));
        dispatch(getCompanyCustomerListAsync({page: 1, page_size: 150000}));
        dispatch(getDistrictListAsync({page: 1, page_size: 150000}));
        if (!!districtId || !!district?.id) {
            dispatch(
                getServicesListAsync({
                    page: 1,
                    page_size: 150000,
                    district: districtId || district?.id,
                })
            );
            dispatch(getDistrictListAsync({page: 1, page_size: 150000}));
        }
        dispatch(getDispatcherProfileAsync());
        dispatch(clearExecutor());
        setServicesListData(servicesList);
        setPrice("");
    }, []);

    useEffect(() => {
        setServicesListData(servicesList);
    }, [servicesList]);

    useEffect(() => {
        if (!!customerType) {
            dispatch(
                getCompanyCustomerListAsync({
                    page: 1,
                    page_size: 1500000,
                    type: customerTypeId(customerType),
                })
            );
        }
        if (!!executorId?.id) dispatch(getExecutorByIdAsync(executorId?.id));
    }, [customerType, executorId]);

    useEffect(() => {
        setTimeout(() => {
            setOrganizationsData(organizations);
        }, 0);
    }, [organizations]);

    useEffect(() => {
        setServiceDataId(serviceData?.id);
        if (serviceDataId || serviceData?.id) {
            dispatch(getDistrictListAsync({page: 1, page_size: 150000}));
            setPrice(
                (convertPrice(serviceData?.price, "front") * quantity).toFixed(2)
            );
        }
    }, [serviceData, setServiceData, quantity, setQuantity]);

    useEffect(() => {
        if (!!districtId || !!district?.id) {
            dispatch(
                getServicesListAsync({
                    page: 1,
                    page_size: 150000,
                    district: districtId || district?.id,
                })
            );
        }
    }, [districtId, district?.id]);

    return (
        <AppNewModalContext.Provider
            value={{
                customerTypes,
                district,
                setDistrict,
                setDistrictId,
                servicesList: servicesListData,
                organizationsData,
                setModalView,
                discountList,
                setCustomerType,
                setCustomerComment,
                setCustomerId: setCustomerIdData,
                setQuantity,
                quantity,
                setPrice,
                price,
                isShowDDIcon: true,
                setDispatcherComment,
                executor,
                executorId,
                organizationId,
                setTime,
                time,
                setDate,
                date,
                setOrganizationId,
                createOrder: createDispatcherOrder,
                closeModal,
                serviceData,
                setServiceData,
                setAddress,
                setAddressRoute1,
                setAddressRoute2,
                customerType,
                customerId,
                customerComment,
                dispatcherComment,
                dispatcherOrder,
                addressRoute1,
                addressRoute2,
                address,
                setExecutorId,
                serviceDataId,
                isCreated,
            }}
        >
            {modalView === "app" && <ApplicationNewModalAppMode/>}
            {modalView === "executor" && (
                <ApplicationNewModalExecutorMode mode={"new"}/>
            )}
        </AppNewModalContext.Provider>
    );
};

ApplicationNewModal.defaultProps = {
    closeModal: () => {
    },
    options: {},
};

ApplicationNewModal.propTypes = {
    closeModal: PropTypes.func,
    options: PropTypes.object,
};

export default ApplicationNewModal;
