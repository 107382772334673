import { createSlice } from "@reduxjs/toolkit";
import api from "../../api";
import { APIUrlPrefix } from "../../../utils/constants";
import { errorHandler } from "../../../scripts/commonScripts";

const dispatcherProfileSlice = createSlice({
  name: "dispatcherProfile",
  initialState: {
    dispatcherProfile: {},
  },
  reducers: {
    setDispatcherProfile: (state, action) => {
      state.dispatcherProfile = action.payload;
    },
  },
});

export const getDispatcherProfileAsync = () => async (dispatch) => {
  await api
    .get(`${APIUrlPrefix}/dispatcher/profile/my/`)
    .then((r) => {
      dispatch(setDispatcherProfile(r?.data));
    })
      .catch((e) => {
        errorHandler({
          error: e,
          dispatch,
          text: "",
          requestName: "getDispatcherProfileAsync",
        });
      });
};

export const { setDispatcherProfile } = dispatcherProfileSlice.actions;
export const dispatcherProfile = (state) =>
  state.dispatcherProfile.dispatcherProfile;
export default dispatcherProfileSlice.reducer;
