import React, { useEffect, useState } from "react";
import "./styles/SearchBar.scoped.scss";
import "../../styles/Tables.scss";
import "../AppButton/styles/AppButton.scoped.scss";
import SvgSelector from "../SvgSelector/SvgSelector";
import classNames from "classnames";
import {
  empListFilters,
  infoPanelButtons,
  modalKeys,
  RouterPath,
  urListFilters,
} from "../../../utils/utils";
import EventEmitter from "../../../utils/EventEmitter";
import AppInput from "../AppInput/AppInput";
import AppButton from "../AppButton/AppButton";
import { uid } from "uid";
import { Mounted } from "../../../scripts/hooks";
import { useDispatch, useSelector } from "react-redux";
import { getDispatcherProfileAsync } from "../../../store/reducers/dispatcher/profile";
import { showModal } from "../../../store/reducers/modals";
import {
  accountPayMeasureConvert,
  defaultFilter,
  filterActionSearchBar,
  filtersSearchBar,
} from "../../../scripts/table";
import AppPaginator from "../AppPaginator/AppPaginator";
import {itemsPerPagePaginator} from "../../../utils/constants";
import NavbarCardTop from "../Navbar/components/navbar-card-top/NavbarCardTop";
import { getDispatcherOrderByIdAsync } from "../../../store/reducers/dispatcher/order";
import AppEmpty from "../AppEmpty/AppEmpty";
import {
  convertPrice,
  statusFormat,
  timesName,
} from "../../../scripts/commonScripts";
import EmpListTableFragment from "./fragments/EmpListTableFragment";
import ServTableFragment from "./fragments/ServTableFragment";
import AreasTableFragment from "./fragments/AreasTableFragment";
import AppSelect from "../AppSelect/AppSelect";
import { getDistrictListAsync } from "../../../store/reducers/common/catalog";

const AppMainTable = ({
  itemList,
  itemListTotalCount,
  headerList,
  mode,
  title,
  search,
  servicesList,
  isShowSearch,
  isShowSearchBarTop,
  isHeadCenter,
  perPage,
}) => {
  const dispatch = useDispatch();
  const currLocation = window.location.pathname;
  const { districtList } = useSelector((state) => state.commonCatalog);
  const { dispatcherProfile } = useSelector((state) => state.dispatcherProfile);
  const filterData = useSelector((state) => state.filters);
  const { wait_approve, in_progress, finished, canceled } = useSelector(
    (state) => state.dispatcherOrder.dispatcherOrderCount
  );
  const [itemsData, setItemsData] = useState([]);
  const [selectedIdx] = useState([]);
  const [searchTermDispatcher, setSearchTermDispatcher] = useState("");
  const [searchTermExecutor, setSearchTermExecutor] = useState("");
  const [searchTermUr, setSearchTermUr] = useState("");
  const [currentFilter, setCurrentFilter] = useState(
    defaultFilter(filterData, currLocation, dispatcherProfile)
  );
  const [empListFilter, setEmpListFilter] = useState(
    localStorage.getItem("empListFilter")
  );
  const [urListFilter, setUrListFilter] = useState(
    localStorage.getItem("urListFilter")
  );
  const [paginatorValue, setPaginatorValue] = useState(1);

  const searchbarFilterStyles = classNames(
    "searchbar__filter",
    searchbarFilterStyleModes()
  );
  const searchbarStyles = classNames("searchbar", searchbarStyleModes());
  const tableStyles = classNames("table", tableStyleModes());
  const tableContainerStyles = classNames(
    "table__container",
    tableContainerStyleModes()
  );
  const headTextStyles = classNames(
    "d-td d-td__head-text",
    headTextStylesModes()
  );
  const accountLinksStyles = (isDisabled) =>
    classNames(
      "app-button app-button_blue app-button_min",
      accountLinksStylesModes(isDisabled)
    );
  const appTableInfoPanel = [
    {
      text: infoPanelButtons.processed,
      svgId: "processes",
      chipsStyles: "table__button-chips table__button-chips_blue",
      count: wait_approve,
    },
    {
      text: infoPanelButtons.inWork,
      svgId: "hammer",
      chipsStyles: "table__button-chips table__button-chips_lime",
      count: in_progress,
    },
    {
      text: infoPanelButtons.closed,
      svgId: "keys",
      chipsStyles: "table__button-chips table__button-chips_green",
      count: finished,
    },
    {
      text: infoPanelButtons.rejected,
      svgId: "bucket",
      chipsStyles: "table__button-chips table__button-chips_red",
      count: canceled,
    },
    {
      text: infoPanelButtons.all,
      svgId: "summary",
      chipsStyles: "table__button-chips table__button-chips_grey",
      count: canceled + finished + in_progress + wait_approve,
    },
  ];

  function accountLinksStylesModes(isDisabled) {
    if (isDisabled) return "app-button_disabled";
  }

  function headTextStylesModes() {
    if (isHeadCenter) return "d-td__head-text-center";
  }

  function searchbarStyleModes() {
    if (!isShowSearchBarTop) return "searchbar__without-top";
  }

  function tableStyleModes() {
    if (!isShowSearchBarTop) return "table__without-top";
  }

  const searchbarButtonStyles = (option) => {
    return classNames(
      searchbarButtonsStyleModes(),
      "searchbar__filter-button",
      searchbarButtonsStyleActive(option)
    );
  };

  function searchbarButtonsStyleActive(option) {
    if (option === currentFilter) return "active";
    else return "";
  }

  function searchbarButtonsStyleModes() {
    if (currLocation === RouterPath.summary) return "tiny-btn";
    else return "";
  }

  function tableContainerStyleModes() {
    if (currLocation === RouterPath.apps) return "table__container-min";
    if (!isShowSearchBarTop) return "table__container-minimum";
    else return "";
  }

  function searchbarFilterStyleModes() {
    if (currLocation === RouterPath.summary) return "searchbar__filter-many";
  }

  function payMonthConvert(month) {
    if (month.toString().length === 1) return `0${month}`;
    else return month;
  }
  function statusConvert(status) {
    const statuses = {
      wait_payment: "Ожидает оплаты",
      waiting: "Ожидает оплаты",
      paid: "Оплачено",
      overdue: "Платеж просрочен",
    };
    return statuses[status];
  }

  function statusStyles(status) {
    const statuses = {
      wait_payment: "pay-status__yellow",
      waiting: "pay-status__yellow",
      paid: "pay-status__green",
      overdue: "pay-status__red",
    };
    return statuses[status];
  }

  function billTypeConvert(type) {
    const types = {
      certificate: "Акт выполненных работ",
      bill: "Счет на оплату, плат.поручение",
    };
    return types[type];
  }

  const handleSearch = (e) => {
    if (localStorage.getItem("empListFilter") === empListFilters.dispatchers) {
      setSearchTermDispatcher(e);
    }
    if (localStorage.getItem("empListFilter") === empListFilters.executors) {
      setSearchTermExecutor(e);
    }
    if (localStorage.getItem("urListFilter")) {
      setSearchTermUr(e);
    }
    EventEmitter.emit("search", e);
  };

  function openFilterModal() {
    dispatch(showModal({ key: modalKeys.filterModal, services: servicesList }));
  }

  function openModal(option, idx) {
    if (mode === "empListTable") {
      if (empListFilter === empListFilters.executors) {
        dispatch(
          showModal({
            key: modalKeys.executorAccModal,
            option: option,
            idx: idx,
            mode: "edit",
            services: servicesList,
          })
        );
      }
      if (empListFilter === empListFilters.dispatchers)
        dispatch(
          showModal({
            key: modalKeys.dispatcherAddModal,
            option: option,
            idx: idx,
            mode: "edit",
          })
        );
    }
    if (["accountTable", "accountTableLegal"].includes(mode)) {
      dispatch(
        showModal({ key: modalKeys.payModal, option: option, idx: idx })
      );
    }
    if (mode === "appTable") {
      dispatch(getDispatcherOrderByIdAsync(option?.id));
      dispatch(
        showModal({
          key: modalKeys.applicationProcessModal,
          option: option,
          idx: idx,
        })
      );
    }
    if (mode === "servTable") {
      dispatch(
        showModal({ key: modalKeys.servAddModal, option: option, idx: idx, page: paginatorValue })
      );
    }
    if (mode === "urListTable") {
      if (urListFilter === urListFilters.urs) {
        dispatch(
          showModal({
            key: modalKeys.urModalAdd,
            option: option,
            idx: idx,
            mode: "edit",
          })
        );
      }
      if (urListFilter === urListFilters.ips)
        dispatch(
          showModal({
            key: modalKeys.IPModalAdd,
            option: option,
            idx: idx,
            mode: "edit",
          })
        );
    }
  }

  function setItems(itemList) {
    setItemsData(itemList);
  }

  function setPaginatorPage(value) {
    EventEmitter.emit("page", value);
    setPaginatorValue(value);
  }

  Mounted(() => {
    dispatch(getDispatcherProfileAsync());
    dispatch(getDistrictListAsync({page: 1, page_size: 150000 }));
    if (!localStorage.getItem("urListFilter")) {
      localStorage.setItem("urListFilter", "Юр.лица");
    } else {
      setInterval(() => {
        setUrListFilter(localStorage.getItem("urListFilter"));
      }, 300);
    }
  });

  Mounted(() => {
    if (!localStorage.getItem("empListFilter")) {
      localStorage.setItem("empListFilter", empListFilters.executors);
    } else {
      setInterval(() => {
        setEmpListFilter(localStorage.getItem("empListFilter"));
      }, 300);
    }
  });

  useEffect(() => {
    setItems(itemList);
    const addData = (eventData) => {
      setItemsData((prevValue) => [...prevValue, eventData]);
    };
    const addServiceListener = EventEmitter.addListener(
      "addNewService",
      addData
    );
    const addNewEmployerListener = EventEmitter.addListener(
      "addNewEmployer",
      addData
    );
    return () => {
      addNewEmployerListener.remove();
      addServiceListener.remove();
    };
  });

  function filterApplications(value) {
    if (currLocation === RouterPath.apps) {
      localStorage.setItem("appsFilter", value);
      if (value === "Все") {
        EventEmitter.emit("AppsFiltersStatus", { status: "" });
      }
      if (value === "Ожидает подтверждения") {
        EventEmitter.emit("AppsFiltersStatus", { status: "wait_approve" });
      }
      if (value === "В работе") {
        EventEmitter.emit("AppsFiltersStatus", {
          status: "approved,in_progress",
        });
      }
      if (value === "Завершено") {
        EventEmitter.emit("AppsFiltersStatus", {
          status: ["work_is_done", "finished", "wait_payment"],
        });
      }
      if (value === "Отклонено") {
        EventEmitter.emit("AppsFiltersStatus", { status: "canceled" });
      }
    }
  }

  function docStatus({ type, docs }) {
    if (type === "cert") {
      const arrCert = docs.filter((el) => el.type === "certificate");
      if (arrCert.length === 1) return "Не получено";
      else return "Получено";
    }
    if (type === "act") {
      const arrAct = docs.filter((el) => el.type === "bill");
      if (arrAct.length === 1) return "Не получен";
      else return "Получен";
    }
  }

  function docsStyles({ type, docs }) {
    if (type === "cert") {
      const arrCert = docs.filter((el) => el.type === "certificate");
      if (arrCert.length === 1) return "doc__red";
      else return "doc__green";
    }
    if (type === "act") {
      const arrAct = docs.filter((el) => el.type === "bill");
      if (arrAct.length === 1) return "doc__red";
      else return "doc__green";
    }
  }

  function statusAppStylesModes(status) {
    if (["work_is_done", "finished", "wait_payment"].includes(status))
      return "d-td__status_green";
    if (["approved", "in_progress"].includes(status))
      return "d-td__status_yellow";
    if (status === "canceled") return "d-td__status_red";
    if (status === "wait_approve") return "d-td__status_blue";
  }

  const statusAppStyles = (status) =>
    classNames("d-td", statusAppStylesModes(status));

  return (
    <div>
      <div className={tableStyles}>
        <div className={searchbarStyles}>
          {isShowSearchBarTop && (
            <div className="searchbar-top">
              <h2 className="searchbar__title">{title}</h2>
              <div className="searchbar__buttons">
                {currLocation === RouterPath.typesOfServices && (
                  <AppSelect
                    optionsList={districtList}
                    onInput={(e) => {
                      EventEmitter.emit("filterDistrict", { id: e });
                    }}
                  />
                )}
                {[
                  RouterPath.empList,
                  RouterPath.account,
                  RouterPath.urList,
                  // RouterPath.areas,
                ].includes(currLocation) &&
                  !selectedIdx.length && (
                    <div style={{ display: "flex", gap: "10px" }}>
                      <div className={searchbarFilterStyles}>
                        {filtersSearchBar(
                          currLocation,
                          dispatcherProfile,
                          filterData,
                          servicesList
                        ).map((option, idx) => {
                          return (
                            <AppButton
                              key={idx}
                              className={searchbarButtonStyles(option)}
                              id={`filter-${uid()}`}
                              mode="search-filter"
                              onClick={() =>
                                filterActionSearchBar(
                                  option,
                                  setCurrentFilter,
                                  currLocation,
                                  searchTermDispatcher,
                                  searchTermExecutor,
                                  searchTermUr
                                )
                              }
                            >
                              {option}
                            </AppButton>
                          );
                        })}
                      </div>
                      {[RouterPath.empList, RouterPath.account].includes(
                        currLocation
                      ) && (
                        <div
                          className="searchbar__filter-icon"
                          onClick={() => openFilterModal()}
                        >
                          <SvgSelector id="filter-icon" />
                        </div>
                      )}
                    </div>
                  )}
              </div>
            </div>
          )}
          <div className="searchbar-bottom">
            <div className="searchbar-wrapper">
              {[
                RouterPath.empList,
                RouterPath.apps,
                RouterPath.typesOfServices,
                RouterPath.account,
                RouterPath.urList,
              ].includes(currLocation) &&
                isShowSearch && (
                  <AppInput
                    id="logo"
                    svgId="input-search-icon"
                    iconPosition="left"
                    placeholder={search}
                    type={search}
                    mode="with-icon big"
                    onInput={handleSearch}
                  />
                )}
            </div>
            <div>
              {[
                RouterPath.empList,
                RouterPath.apps,
                RouterPath.typesOfServices,
                RouterPath.urList,
              ].includes(currLocation) && (
                <div className="navbar__bottom bottom">
                  <NavbarCardTop />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={tableContainerStyles}>
          {mode === "appTable" && (
            <div className="table__buttons">
              {!!appTableInfoPanel?.length &&
                appTableInfoPanel.map((option, idx) => {
                  return (
                    <button
                      key={idx}
                      className="table__button"
                      onClick={() => filterApplications(option.text)}
                    >
                      <div className="table__button-text">{option.text}</div>
                      <div className={option.chipsStyles}>{option.count}</div>
                    </button>
                  );
                })}
            </div>
          )}
          {!itemsData?.length && (
            <div className="d-tr__no-data">
              <AppEmpty desc="Нет данных!" />
            </div>
          )}
          {!!itemsData?.length && (
            <div className="d-table__wrapper">
              <div className="d-table">
                <div className="d-head">
                  {headerList?.length &&
                    headerList.map((option, idx) => {
                      return (
                        <div className={headTextStyles} key={idx}>
                          {option}
                        </div>
                      );
                    })}
                </div>
                {!!itemsData?.length &&
                  itemsData.map((option, idx) => {
                    return (
                      <div className="d-tr">
                        {mode === "accountTableLegal" && (
                          <React.Fragment key={idx}>
                            <div
                              className="d-td d-td__payer-number"
                              onClick={() => openModal(option, idx)}
                            >
                              {option.id}
                            </div>
                            <div
                              className="d-td d-td__number"
                              onClick={() => openModal(option, idx)}
                            >
                              {option.customer_name}
                            </div>
                            <div
                              className="d-td d-td__number"
                              onClick={() => openModal(option, idx)}
                            >
                              {payMonthConvert(option.pay_month)}.
                              {option.pay_year}
                            </div>
                            <div
                              className="d-td d-td__number"
                              onClick={() => openModal(option, idx)}
                            >
                              <div className={statusStyles(option.status)}>
                                {statusConvert(option.status)}
                              </div>
                            </div>
                            <div
                              className="d-td d-td__summa"
                              onClick={() => openModal(option, idx)}
                            >
                              {convertPrice(option.amount, "front")} ₽
                            </div>
                            <div
                              className="d-td d-td__number"
                              onClick={() => openModal(option, idx)}
                            >
                              <div
                                className={docsStyles({
                                  type: "act",
                                  docs: option?.bill_documents,
                                })}
                              >
                                {docStatus({
                                  type: "act",
                                  docs: option?.bill_documents,
                                })}
                              </div>
                            </div>
                            <div
                              className="d-td d-td__number"
                              onClick={() => openModal(option, idx)}
                            >
                              <div
                                className={docsStyles({
                                  type: "cert",
                                  docs: option?.bill_documents,
                                })}
                              >
                                {docStatus({
                                  type: "cert",
                                  docs: option?.bill_documents,
                                })}
                              </div>
                            </div>
                          </React.Fragment>
                        )}
                        {mode === "accountTable" && (
                          <React.Fragment key={idx}>
                            <div
                              className="d-td d-td__payer-number"
                              onClick={() => openModal(option, idx)}
                            >
                              {option.id}
                            </div>
                            <div
                              className="d-td d-td__number"
                              onClick={() => openModal(option, idx)}
                            >
                              {option.customer_name}
                            </div>
                            <div
                              className="d-td d-td__number"
                              onClick={() => openModal(option, idx)}
                            >
                              {payMonthConvert(option.pay_month)}.
                              {option.pay_year}
                            </div>
                            <div
                              className="d-td__number"
                              onClick={() => openModal(option, idx)}
                            >
                              <div className={statusStyles(option.status)}>
                                {statusConvert(option.status)}
                              </div>
                            </div>
                            <div
                              className="d-td d-td__summa"
                              onClick={() => openModal(option, idx)}
                            >
                              {convertPrice(option.amount, "front")} ₽
                            </div>
                            <div
                              className="d-td__number"
                              onClick={() => openModal(option, idx)}
                            >
                              <div
                                className={docsStyles({
                                  type: "act",
                                  docs: option?.bill_documents,
                                })}
                              >
                                {docStatus({
                                  type: "act",
                                  docs: option?.bill_documents,
                                })}
                              </div>
                            </div>
                          </React.Fragment>
                        )}
                        {mode === "accountPay" && (
                          <React.Fragment key={idx}>
                            <div className="d-td d-td__payer-number">
                              {option.id}
                            </div>
                            <div className="d-td d-td__payer-number">
                              {option.date}, {timesName(option.time)}
                            </div>
                            <div className="d-td d-td__payer-number">
                              {option.service.name}
                            </div>
                            <div className="d-td d-td__payer-number">
                              {option.quantity}{" "}
                              {accountPayMeasureConvert(option.service.measure)}
                            </div>
                            <div className="d-td d-td__payer-number">
                              {convertPrice(option.price, "front")} ₽
                            </div>
                          </React.Fragment>
                        )}
                        {mode === "accountOrdersDocs" && (
                          <React.Fragment key={idx}>
                            <div
                              className="d-td d-td__doc-title"
                              onClick={() => openModal(option, idx)}
                            >
                              {billTypeConvert(option?.type)}
                            </div>
                            <div
                              className="d-td d-td__number"
                              onClick={() => openModal(option, idx)}
                            >
                              <div style={{ marginRight: "10px" }}>
                                <a
                                  className={accountLinksStyles(!option?.file0)}
                                  href={option?.file0}
                                  target="_blank"
                                  download
                                  rel="noreferrer"
                                >
                                  Скачать
                                </a>
                              </div>
                            </div>
                            <div
                              className="d-td d-td__number"
                              onClick={() => openModal(option, idx)}
                            >
                              <a
                                className={accountLinksStyles(!option?.file1)}
                                href={option?.file1}
                                target="_blank"
                                download
                                rel="noreferrer"
                              >
                                Скачать
                              </a>
                            </div>
                          </React.Fragment>
                        )}
                        {mode === "appTable" && (
                          <React.Fragment key={idx}>
                            <div
                              className="d-td d-td__date"
                              onClick={() => openModal(option, idx)}
                            >
                              {option?.id}
                            </div>
                            <div
                              className="d-td d-td__date"
                              onClick={() => openModal(option, idx)}
                            >
                              {option?.customer_name}
                            </div>
                            <div
                              className="d-td d-td__date"
                              onClick={() => openModal(option, idx)}
                            >
                              {option?.date} - {timesName(option?.time)}
                            </div>
                            <div className="d-td d-td__date"
                                 onClick={() => openModal(option, idx)}
                            >
                              {option?.district?.name}
                            </div>
                            <div
                              className="d-td d-td__name"
                              onClick={() => openModal(option, idx)}
                            >
                              {option?.service?.name}
                            </div>
                            <div
                              className={statusAppStyles(option?.status)}
                              onClick={() => openModal(option, idx)}
                            >
                              <div className="d-td__status">
                                {statusFormat(option?.status)}
                              </div>
                            </div>
                          </React.Fragment>
                        )}
                        {/*{mode === "appTable" && dispatcherProfile?.is_admin && (*/}
                        {/*    <div*/}
                        {/*        className="d-td d-td__name"*/}
                        {/*        onClick={() => openModal(option, idx)}*/}
                        {/*    >*/}
                        {/*      {option?.district?.name}*/}
                        {/*    </div>*/}
                        {/*)}*/}
                        {mode === "empListTable" && (
                          <EmpListTableFragment
                            openModal={openModal}
                            option={option}
                            idx={idx}
                            servicesList={servicesList}
                            empListFilter={empListFilter}
                          />
                        )}
                        {mode === "servTable" && (
                          <ServTableFragment
                            openModal={openModal}
                            option={option}
                            idx={idx}
                            servicesList={servicesList}
                          />
                        )}
                        {mode === "areasTable" && (
                          <AreasTableFragment
                            key={option?.id}
                            openModal={openModal}
                            option={option}
                            idx={idx}
                            servicesList={servicesList}
                            page={paginatorValue}
                          />
                        )}
                        {mode === "urListTable" && (
                          <React.Fragment key={idx}>
                            <div
                              className="d-td d-td__number"
                              onClick={() => openModal(option, idx)}
                            >
                              {option?.id}
                            </div>
                            <div
                              className="d-td d-td__number"
                              onClick={() => openModal(option, idx)}
                            >
                              {option?.full_name ||
                                option?.short_name ||
                                option?.ceo_fio}
                            </div>
                            <div
                              className="d-td d-td__number"
                              onClick={() => openModal(option, idx)}
                            >
                              {option?.phone}
                            </div>
                            <div
                              className="d-td d-td__number"
                              onClick={() => openModal(option, idx)}
                            >
                              {option?.inn}
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
        <AppPaginator
          className="table__paginator"
          totalPages={itemListTotalCount / perPage}
          value={paginatorValue}
          pageSetterFunc={setPaginatorPage}
        />
      </div>
    </div>
  );
};

AppMainTable.defaultProps = {
  isShowSearch: true,
  isShowSearchBarTop: true,
  isHeadCenter: false,
  perPage: itemsPerPagePaginator,
};

export default AppMainTable;
