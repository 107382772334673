import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppMainTable from "../../layout/AppMainTable/AppMainTable";
import { getDispatcherPaymentBillListAsync } from "../../../store/reducers/dispatcher/payment";
import EventEmitter from "../../../utils/EventEmitter";
import { financesPerPage, updatePagesPeriod } from "../../../utils/constants";
import { RouterPath } from "../../../utils/utils";
import { getDictionariesAsync } from "../../../store/reducers/common/catalog";

const Account = () => {
  const currLocation = window.location.pathname;
  const dispatch = useDispatch();
  const { accountTableLegal, accountTable } = useSelector(
    (state) => state.tables
  );
  const { dispatcherPaymentBillList, dispatcherPaymentBillListCount } =
    useSelector((state) => state.dispatcherPayment);
  const [dispatcherPaymentBillListData, setDispatcherPaymentBillListData] =
    useState(dispatcherPaymentBillList);
  const [
    dispatcherPaymentBillListCountData,
    setDispatcherPaymentBillListCountData,
  ] = useState(dispatcherPaymentBillListCount);
  const [customerType, setCustomerType] = useState(localStorage.getItem('legal-id'));
  const [page, setPage] = useState(1);
  const [q, setQ] = useState("");
  const [ordering, setOrdering] = useState("");
  const [orderingMonth, setOrderingMonth] = useState("");
  const [orderingYear, setOrderingYear] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    dispatch(getDictionariesAsync());
    dispatch(
      getDispatcherPaymentBillListAsync({
        page_size: financesPerPage,
        customer_type: customerType,
        ordering: ordering,
        pay_month: orderingMonth,
        pay_year: orderingYear,
        status: status,
        page,
        q,
      })
    );
    localStorage.setItem("accountTabCustomerTypeId", customerType);
  }, []);

  useEffect(() => {
    const updatePage = setInterval(() => {
      if (currLocation === RouterPath.account) {
        dispatch(
          getDispatcherPaymentBillListAsync({
            page_size: financesPerPage,
            customer_type: customerType,
            ordering: ordering,
            pay_month: orderingMonth,
            pay_year: orderingYear,
            status: status,
            page,
            q,
          })
        );
        localStorage.setItem("accountTabCustomerTypeId", customerType);
      }
    }, updatePagesPeriod);
    return () => clearInterval(updatePage);
  }, [
    currLocation,
    customerType,
    dispatch,
    ordering,
    orderingMonth,
    orderingYear,
    page,
    q,
    status,
  ]);

  useEffect(() => {
    setTimeout(() => {
      setDispatcherPaymentBillListData(dispatcherPaymentBillList);
    }, 0);
    setTimeout(() => {
      setDispatcherPaymentBillListCountData(dispatcherPaymentBillListCount);
    }, 0);
  }, [dispatcherPaymentBillList, dispatcherPaymentBillListCount]);

  useEffect(() => {
    dispatch(
      getDispatcherPaymentBillListAsync({
        page_size: financesPerPage,
        customer_type: customerType,
        page,
        q,
      })
    );
    localStorage.setItem("accountTabCustomerTypeId", customerType);
  }, [customerType, page]);

  useEffect(() => {
    const accountCustomerTypeListener = EventEmitter.addListener(
      "accountCustomerType",
      (e) => setCustomerType(e?.customer_type)
    );
    const pageListener = EventEmitter.addListener("page", (page) =>
      setPage(page)
    );
    const filterModalListener = EventEmitter.addListener(
      "filterModalEvent",
      (e) => {
        if (!!e?.customer_type) setCustomerType(e?.customer_type);
        if (!!e?.ordering) setOrdering(e?.ordering);
        if (!!e?.orderingMonth) setOrderingMonth(e?.orderingMonth);
        if (!!e?.orderingYear) setOrderingYear(e?.orderingYear);
        if (!!e?.status.join(",")) setStatus(e?.status.join(","));
        dispatch(
          getDispatcherPaymentBillListAsync({
            page,
            page_size: financesPerPage,
            customer_type: e?.customer_type || customerType,
            ordering: e?.ordering,
            pay_month: e?.orderingMonth,
            pay_year: e?.orderingYear,
            status: e?.status.join(","),
            q,
          })
        );
      }
    );
    const searchListener = EventEmitter.addListener("search", (e) => {
      setQ(e);
      dispatch(
        getDispatcherPaymentBillListAsync({
          page,
          page_size: financesPerPage,
          customer_type: customerType,
          ordering: ordering,
          pay_month: orderingMonth,
          pay_year: orderingYear,
          status: status,
          q: e,
        })
      );
    });
    return () => {
      pageListener.remove();
      accountCustomerTypeListener.remove();
      filterModalListener.remove();
      searchListener.remove();
    };
  });

  return (
    <AppMainTable
      title="Финансы"
      search="Найти плательщика"
      headerList={
        localStorage.getItem("accountTabCustomerTypeId") === localStorage.getItem('legal-id')
          ? accountTableLegal
          : accountTable
      }
      itemList={dispatcherPaymentBillListData}
      itemListTotalCount={dispatcherPaymentBillListCountData}
      mode={
        localStorage.getItem("accountTabCustomerTypeId") === localStorage.getItem('legal-id')
          ? "accountTableLegal"
          : "accountTable"
      }
      perPage={financesPerPage}
    />
  );
};

export default Account;
