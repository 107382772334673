import React from "react";
import SvgSelector from "../SvgSelector/SvgSelector";
import './styles/AppEmpty.scoped.scss';
import PropTypes from "prop-types";

const AppEmpty = ({ desc }) => {
  return (
    <div className="empty">
      <div className="empty__content">
          <SvgSelector id="sad-smile" className="empty__img" />
          <div className="empty__desc">{desc}</div>
      </div>
    </div>
  );
};

AppEmpty.defaultProps = {
    desc: 'Не найдено'
}

AppEmpty.propTypes = {
    desc: PropTypes.string
}

export default AppEmpty;
