import React, { useEffect } from "react";
import "../../layout/AppMainTable/styles/SearchBar.scoped.scss";
import "./styles/MapPage.scoped.scss";
import { useDispatch, useSelector } from "react-redux";
import AppMapWrapper from "../../layout/AppMap/AppMapWrapper";
import { getExecutorsLocationsListAsync } from "../../../store/reducers/dispatcher/account";

const MapPage = () => {
  const dispatch = useDispatch();

  const { executorsLocationsList } = useSelector(
    (state) => state.dispatcherAccount
  );

  useEffect(() => {
    dispatch(getExecutorsLocationsListAsync());
    setInterval(() => {
      dispatch(getExecutorsLocationsListAsync());
    }, 60 * 1000);
  }, []);

  return (
    <div className="map-page">
      <div className="searchbar">
        <h2 className="searchbar__title">Карта</h2>
      </div>
      <div className="map-page__map">
        <AppMapWrapper mapPointsList={executorsLocationsList} zoom={15} />
      </div>
    </div>
  );
};

export default MapPage;
