import { createSlice } from '@reduxjs/toolkit';
import {empListFilters} from "../../utils/utils";

const filterSlice = createSlice({
    name: 'filters',
    initialState: {
        empListNotAdmin: [empListFilters.executors],
        empListAdmin: [empListFilters.executors, empListFilters.dispatchers],
        application: ["Все", "Ожидает подтверждения", "В работе", "Завершено", "Отклонено"],
        account: ["Предприятия", "Физ.лица"],
        urList: ["Юр.лица", "ИП"],
    },
    reducers: {}
})

export default filterSlice.reducer;
