import React, {useLayoutEffect, useState} from "react";
import AppSelect from "../../../../../../../../layout/AppSelect/AppSelect";
import AppButton from "../../../../../../../../layout/AppButton/AppButton";
import AppTextarea from "../../../../../../../../layout/AppTextarea/AppTextarea";
import "../../../../../../styles/ApplicationNew.scoped.scss";
import {useSelector} from "react-redux";

const ApplicationNewModalAddressSelector = ({
  destination,
  setAddress,
  address,
  setAddressRoute1,
  addressRoute1,
  setAddressRoute2,
  addressRoute2,
  setNewAddress,
  newAddress,
  setNewAddressComment,
  newAddressComment,
  createDispatcherCustomerAddress,
  editDispatcherCustomerAddress,
  customerId,
  serviceData,
  customerType,
  statusData,
}) => {
  const { dispatcherCustomerAddressList } = useSelector(
      (state) => state.dispatcherAccount
  );
  const [addressMode, setAddressMode] = useState("new");
  const [address0, setAddress0] = useState();
  const [addressRoute1Data, setAddressRoute1Data] = useState();
  const [addressRoute2Data, setAddressRoute2Data] = useState();

  function findAddressInDispatcherCustomerAddressList(addressId) {
    if (!!dispatcherCustomerAddressList?.length && !!addressId) {
      return dispatcherCustomerAddressList.filter((el) => el?.id === addressId)[0];
    } else return { id: 0, name: "Укажите значение" };
  }

  function toggleAddressMode() {
    addressMode === "new" ? setAddressMode("edit") : setAddressMode("new");
  }

  useLayoutEffect(() => {
    if (!!address) {
      setAddress0(findAddressInDispatcherCustomerAddressList(address));
    }
  }, [address, dispatcherCustomerAddressList])

  useLayoutEffect(() => {
    if (!!addressRoute1) {
      setAddressRoute1Data(
          findAddressInDispatcherCustomerAddressList(addressRoute1)
      );
    }
  }, [addressRoute1, dispatcherCustomerAddressList])

  useLayoutEffect(() => {
    if (!!addressRoute2) {
      setAddressRoute2Data(
          findAddressInDispatcherCustomerAddressList(addressRoute2)
      );
    }
  }, [addressRoute2, dispatcherCustomerAddressList])

  return (
    <div className="address-area__container">
      {(!customerType || !customerId || !serviceData) && (
        <div>Для работы с адресами заявителя:</div>
      )}
      {!customerType && <div>Выберите статус плательщика!</div>}
      {!customerId && <div>Выберите плательщика!</div>}
      {!serviceData && <div>Выберите услугу!</div>}
      {!!customerType &&
        !!customerId &&
        !!serviceData &&
        !!dispatcherCustomerAddressList?.length &&
        destination === "address" && (
          <AppSelect
            mode="white"
            label="Адреса заявителя"
            placeholder="Введите адрес"
            validations={{ isEmpty: true }}
            onInput={setAddress}
            optionsList={dispatcherCustomerAddressList}
            selected={address0}
            isDisabled={['work_is_done', 'wait_payment', 'finished', 'canceled'].includes(statusData)}
            isReq={true}
          />
        )}
      {!!customerType &&
        !!customerId &&
        !!serviceData &&
        !dispatcherCustomerAddressList?.length &&
        destination === "address" && (
          <div>У выбранного заявителя нет адресов!</div>
        )}
      {!!customerType &&
        !!customerId &&
        !!serviceData &&
        destination === "route" && (
          <>
            <AppSelect
              label="Адреса заявителя c"
              placeholder="Введите адрес"
              validations={{ isEmpty: true }}
              mode="white"
              onInput={setAddressRoute1}
              optionsList={dispatcherCustomerAddressList}
              selected={addressRoute1Data}
              isDisabled={['work_is_done', 'wait_payment', 'finished', 'canceled'].includes(statusData)}
              isReq={true}
            />
            <AppSelect
              label="Адреса заявителя до"
              placeholder="Введите адрес"
              validations={{ isEmpty: true }}
              mode="white"
              onInput={setAddressRoute2}
              optionsList={dispatcherCustomerAddressList}
              selected={addressRoute2Data}
              isDisabled={['work_is_done', 'wait_payment', 'finished', 'canceled'].includes(statusData)}
              isReq={true}
            />
          </>
        )}
      {!!customerType && !!customerId && !!serviceData && (
        <div className="address-area__container">
          <div className="address-area-name">
            Редактирование адресов заявителя
          </div>
          <AppButton
            onClick={() => toggleAddressMode()}
            mode="blue"
            isDisabled={!dispatcherCustomerAddressList?.length || !!['work_is_done', 'wait_payment', 'finished', 'canceled'].includes(statusData)}
          >
            {addressMode === "new"
              ? "Отредактировать адрес"
              : "Добавить новый адрес"}
          </AppButton>
          {addressMode === "new" && (
            <>
              <AppTextarea
                label="Новый адрес"
                placeholder="Добавьте новый адрес"
                validations={{ isEmpty: true }}
                rows={2}
                mode="white"
                onInput={setNewAddress}
                value={newAddress}
              />
              <AppTextarea
                label="Комментарий к новому адресу"
                placeholder="Добавьте комментарий к новому адресу"
                rows={2}
                mode="white"
                onInput={setNewAddressComment}
                value={newAddressComment}
              />
              <AppButton
                onClick={() => createDispatcherCustomerAddress()}
                mode="blue"
                isDisabled={['work_is_done', 'wait_payment', 'finished', 'canceled'].includes(statusData)}
              >
                Добавить адрес
              </AppButton>
            </>
          )}
          {addressMode === "edit" && destination === "address" && (
            <>
              <AppTextarea
                label="Адрес"
                placeholder="Введите данные адреса"
                validations={{ isEmpty: true }}
                rows={2}
                mode="white"
                onInput={setNewAddress}
                value={
                  findAddressInDispatcherCustomerAddressList(address)?.address
                }
              />
              <AppTextarea
                label="Комментарий к адресу"
                placeholder="Добавьте комментарий к адресу"
                validations={{ isEmpty: true }}
                rows={2}
                mode="white"
                onInput={setNewAddressComment}
                value={
                  findAddressInDispatcherCustomerAddressList(address)?.comment
                }
              />
              <AppButton onClick={() => editDispatcherCustomerAddress(address)}>
                Изменить адрес
              </AppButton>
            </>
          )}
          {addressMode === "edit" && destination === "route" && (
            <>
              <AppTextarea
                label="Адрес c"
                placeholder="Введите данные адреса"
                validations={{ isEmpty: true }}
                rows={2}
                mode="white"
                onInput={setNewAddress}
                value={
                  findAddressInDispatcherCustomerAddressList(addressRoute1)
                    ?.address
                }
              />
              <AppTextarea
                label="Комментарий к адресу c"
                placeholder="Добавьте комментарий к адресу"
                validations={{ isEmpty: true }}
                rows={2}
                mode="white"
                onInput={setNewAddressComment}
                value={
                  findAddressInDispatcherCustomerAddressList(addressRoute1)
                    ?.comment
                }
              />
              <AppButton onClick={() => editDispatcherCustomerAddress(addressRoute1)}>
                Изменить адрес
              </AppButton>

              <AppTextarea
                label="Адрес до"
                placeholder="Введите данные адреса"
                validations={{ isEmpty: true }}
                rows={2}
                mode="white"
                onInput={setNewAddress}
                value={
                  findAddressInDispatcherCustomerAddressList(addressRoute2)
                    ?.address
                }
              />
              <AppTextarea
                label="Комментарий к адресу до"
                placeholder="Добавьте комментарий к адресу"
                validations={{ isEmpty: true }}
                rows={2}
                mode="white"
                onInput={setNewAddressComment}
                value={
                  findAddressInDispatcherCustomerAddressList(addressRoute2)
                    ?.comment
                }
              />
              <AppButton onClick={() => editDispatcherCustomerAddress(addressRoute2)}>
                Изменить адрес
              </AppButton>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ApplicationNewModalAddressSelector;
