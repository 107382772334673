import React, { useContext, useEffect, useState } from "react";
import AppSelect from "../../../../../../../layout/AppSelect/AppSelect";
import AppTextarea from "../../../../../../../layout/AppTextarea/AppTextarea";
import AppInput from "../../../../../../../layout/AppInput/AppInput";
import AppButton from "../../../../../../../layout/AppButton/AppButton";
import "../../../../../styles/ApplicationNew.scoped.scss";
import {
  convertDate,
  convertDateToDot,
  convertQuantities,
  convertTimes,
  customerTypeId,
} from "../../../../../../../../scripts/commonScripts";
import { useDispatch, useSelector } from "react-redux";
import {
  createDispatcherCustomerAddressAsync,
  editDispatcherCustomerAddressAsync,
  getCompanyCustomerListAsync,
  getDispatcherCustomerAddressListAsync,
} from "../../../../../../../../store/reducers/dispatcher/account";
import ApplicationNewModalAddressSelector from "./components/ApplicationNewModalAddressSelector";
import ApplicationSelectExecutor from "../../../../ApplicationSelectExecutor";
import { getDispatcherProfileAsync } from "../../../../../../../../store/reducers/dispatcher/profile";
import { AppNewModalContext } from "../../../ApplicationNewModal";

const ApplicationNewModalAppMode = () => {
  const {
    customerTypes,
    district,
    setDistrict,
    setDistrictId,
    servicesList,
    organizationsData,
    setModalView,
    setCustomerType,
    setCustomerComment,
    setCustomerId,
    setPrice,
    setQuantity,
    quantity,
    setDispatcherComment,
    time,
    setTime,
    setDate,
    date,
    setOrganizationId,
    setServiceData,
    createOrder,
    closeModal,
    serviceData,
    setAddress,
    setAddressRoute1,
    setAddressRoute2,
    customerType,
    customerId,
    customerComment,
    dispatcherComment,
    addressRoute1,
    addressRoute2,
    address,
    price,
    executor,
    executorId,
    organizationId,
    isCreated,
  } = useContext(AppNewModalContext);

  const dispatch = useDispatch();

  const { companyCustomerList } = useSelector(
    (state) => state.dispatcherAccount
  );

  const { districtList } = useSelector((state) => state.commonCatalog);
  const { dispatcherProfile } = useSelector((state) => state.dispatcherProfile);

  const [newAddress, setNewAddress] = useState("");
  const [newAddressComment, setNewAddressComment] = useState("");

  const [currCustomer, setCurrCustomer] = useState(
    companyCustomerList.filter((el) => el.id === customerId)[0]
  );
  const [quantityData, setQuantityData] = useState(
    convertQuantities([quantity], serviceData?.measure)[0]
  );
  const [currTime, setCurrTime] = useState(convertTimes([time])[0]);
  const [priceData, setPriceData] = useState(price);

  function createDispatcherCustomerAddress() {
    dispatch(
      createDispatcherCustomerAddressAsync({
        content_type: customerType,
        object_id: customerId,
        address: newAddress,
        comment: newAddressComment,
      })
    ).then(() => {
      setTimeout(() => {
        dispatch(
          getDispatcherCustomerAddressListAsync({
            content_type: customerType,
            object_id: customerId,
          })
        );
      }, 500);
    });
    setNewAddress("");
    setNewAddressComment("");
  }

  function editDispatcherCustomerAddress(currAddressId) {
    dispatch(
      editDispatcherCustomerAddressAsync(
        {
          content_type: customerType,
          object_id: customerId,
          address: newAddress,
          comment: newAddressComment,
        },
        currAddressId
      )
    ).then(() => {
      setTimeout(() => {
        dispatch(
          getDispatcherCustomerAddressListAsync({
            content_type: customerType,
            object_id: customerId,
          })
        );
      }, 500);
    });
    setNewAddress("");
    setNewAddressComment("");
  }

  useEffect(() => {
    dispatch(getDispatcherProfileAsync());
    const currCustomerId =
      localStorage.getItem("currCustomer") !== "undefined"
        ? JSON.parse(localStorage.getItem("currCustomer"))?.id
        : "";
    const currTimeId =
      localStorage.getItem("currTime") !== "undefined"
        ? JSON.parse(localStorage.getItem("currTime"))?.id
        : "";
    const quantityDataId =
      localStorage.getItem("quantityData") !== "undefined"
        ? JSON.parse(localStorage.getItem("quantityData"))?.id
        : "";
    if (!!currCustomerId)
      setCurrCustomer(
        companyCustomerList.filter((el) => el.id === customerId)[0]
      );
    if (!!currTimeId) setCurrTime(convertTimes([time])[0]);
    if (!!quantityDataId)
      setQuantityData(convertQuantities([quantity], serviceData?.measure)[0]);
    if (localStorage.getItem("createModalPrice")) {
      setPriceData(localStorage.getItem("createModalPrice"));
      setPrice(localStorage.getItem("createModalPrice"));
    }
    if (!dispatcherProfile?.is_admin) {
      setDistrict(dispatcherProfile?.district);
      setDistrictId(dispatcherProfile?.district?.id);
    }
    sessionStorage.setItem("isOpenedCreateServiceModal", "true");
     /* return () => {
          console.log("remove currCustomerId")
          return sessionStorage.removeItem("currCustomerNewAppId")
      }*/
  }, []);

  useEffect(() => {
    localStorage.setItem("createModalPrice", price);
    setPriceData(price);
  }, [price, setPrice, priceData]);

  useEffect(() => {
    if (customerType && customerId) {
      dispatch(
        getCompanyCustomerListAsync({
          page: 1,
          page_size: 15000,
          type: customerTypeId(customerType),
        })
      );
      dispatch(
        getDispatcherCustomerAddressListAsync({
          content_type: customerTypes.filter(el => el.name === customerType)[0].id,
          object_id: customerId,
        })
      );
    }
  }, [customerType, customerId]);

  useEffect(() => {
    const quantityNewApp = sessionStorage.getItem("quantityNewApp");
    const currCustomerNewAppId = sessionStorage.getItem("currCustomerNewAppId");
    const timeNewApp = sessionStorage.getItem("timeNewApp");
    if (!!quantityNewApp) {
      setQuantityData(
        convertQuantities([quantityNewApp], serviceData?.measure)[0]
      );
    }
    if (!!currCustomerNewAppId) {
      setCurrCustomer(
        companyCustomerList.filter((el) => el.id === Number(currCustomerNewAppId))[0]
      );
    }
    if (!!timeNewApp) {
      setCurrTime(convertTimes([timeNewApp])[0]);
    }
  }, [serviceData.measure, companyCustomerList]);

  useEffect(() => {
    localStorage.setItem("customerType", customerType);
    localStorage.setItem("currCustomer", JSON.stringify(currCustomer));
  }, [customerType]);
  useEffect(() => {
    const currTimeId =
      localStorage.getItem("currTime") !== "undefined"
        ? JSON.parse(localStorage.getItem("currTime"))?.id
        : "";
    const quantityDataId =
      localStorage.getItem("quantityData") !== "undefined"
        ? JSON.parse(localStorage.getItem("quantityData"))?.id
        : "";
    if (!quantityDataId) {
      if (!serviceData)
        setQuantityData({ id: "", name: "Выберите количество" });
      setQuantityData({ id: "", name: "Выберите услугу" });
    }
    if (!currTimeId) setCurrTime({ id: "", name: "Выберите время" });
    localStorage.setItem("quantityData", JSON.stringify(quantityData));
    localStorage.setItem("currTime", JSON.stringify(currTime));
  }, [serviceData]);
  return (
    <div className="container">
      <h2 className="modal-name">Создание заявки</h2>
      <div className="app-info">
        <div className="left-side">
          <h3 className="side-name">Заявитель</h3>
          <div className="person-info">
            <AppSelect
              label="Статус"
              optionsList={customerTypes}
              onInput={setCustomerType}
              defaultText="Выберите значение"
              selected={customerTypes.filter((el) => el.name === customerType)[0]}
              mode="white"
              isReq={true}
            />
            <AppSelect
              label="Плательщик"
              defaultText="Выберите статус"
              optionsList={companyCustomerList}
              selected={currCustomer}
              validations={{ isEmpty: true }}
              onInput={(e) => {
                console.log(e,'e')
                sessionStorage.setItem("currCustomerNewAppId", Number(e));
                setCustomerId(e);
              }}
              isDisabled={!customerType}
              isOnInputType={false}
              mode="white"
              isReq={true}
            />
            <AppTextarea
              label="Комментарий к заказу от заявителя"
              placeholder="Добавьте комментарий от клиента"
              validations={{ isEmpty: true }}
              onInput={setCustomerComment}
              rows={2}
              mode="white"
              value={customerComment}
            />
          </div>
          <h3 className="side-name">Адрес</h3>
          <div className="app-body">
            <ApplicationNewModalAddressSelector
              destination={serviceData?.destination}
              setAddress={setAddress}
              address={address}
              setAddressRoute1={setAddressRoute1}
              addressRoute1={addressRoute1}
              setAddressRoute2={setAddressRoute2}
              addressRoute2={addressRoute2}
              setNewAddress={setNewAddress}
              newAddress={newAddress}
              setNewAddressComment={setNewAddressComment}
              newAddressComment={newAddressComment}
              createDispatcherCustomerAddress={createDispatcherCustomerAddress}
              editDispatcherCustomerAddress={editDispatcherCustomerAddress}
              customerId={customerId}
              serviceData={serviceData}
              customerType={customerType}
            />
          </div>
        </div>
        <div className="middle-section">
          <h3 className="side-name">Заявка</h3>
          <div className="middle-section-wrapper">
            {!!dispatcherProfile?.is_admin && (
              <AppSelect
                optionsList={districtList}
                defaultText="Сначала выберите район"
                label="Район"
                selected={district}
                mode="white"
                isDisabled={!dispatcherProfile?.is_admin}
                isReq={true}
                onInput={(id) => {
                  setDistrictId(id);
                  setDistrict(districtList.filter((el) => el.id === id)[0]);
                  setServiceData(0)
                }}
              />
            )}
            {!!servicesList?.length && (
              <AppSelect
                label="Услуга"
                optionsList={servicesList}
                onInput={(serviceId) => {
                  setServiceData(
                      servicesList.filter((el) => el?.id === serviceId)[0]
                  )
                  setQuantity("");
                  setQuantityData({id: '', name: 'Выберите услугу'})
                  sessionStorage.removeItem("quantityNewApp");
                }}
                selected={serviceData}
                defaultText="Категория"
                isOnInputType={false}
                mode="white"
                isDisabled={!district?.id}
                isReq={true}
              />
            )}
            {!servicesList?.length && (
              <div>
                Перед оформлением заявки, настройте тарифы для выбранного
                района!
              </div>
            )}
            {!!servicesList?.length && (
              <AppSelect
                label="Кол-во"
                placeholder="Укажите количество"
                validations={{ isEmpty: true }}
                optionsList={convertQuantities(
                  serviceData?.quantities,
                  serviceData?.measure
                )}
                onInput={(e) => {
                  sessionStorage.setItem("quantityNewApp", e);
                  setQuantity(e);
                  setPriceData("");
                }}
                defaultText="Выберите Кол-во"
                selected={quantityData}
                mode="white"
                isReq={true}
                isDisabled={!serviceData?.id}
              />
            )}
            {!!servicesList?.length && (
              <AppInput
                label="Стоимость заявки (₽)"
                placeholder="₽"
                validations={{ isEmpty: true, isNumbersWithDot: true }}
                onInput={(e) => {
                  setPriceData(e);
                  setPrice(e);
                }}
                mode="white"
                value={!!priceData ? priceData : 0}
                isReq={true}
                isDisabled={!quantity}
              />
            )}
            {/*<div className="discount-area">*/}
            {/*TODO: Вернуть скидки*/}
            {/*{!!discountList?.length && (*/}
            {/*  <>*/}
            {/*    <h3 className="discount-label">Скидка</h3>*/}
            {/*    <div className="discount-variants">*/}
            {/*      {discountList.map((option, idx) => {*/}
            {/*        return (*/}
            {/*          <div className="discount-position" key={idx}>*/}
            {/*            <div>{option?.name}</div>*/}
            {/*            <div className="discount-wrapper">*/}
            {/*              <div>{option?.percent}%</div>*/}
            {/*              <AppCheckbox mode="blue" />*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        );*/}
            {/*      })}*/}
            {/*    </div>*/}
            {/*  </>*/}
            {/*)}*/}
            {/*</div>*/}
            {!!servicesList?.length && (
              <div className="date-form">
                <AppInput
                  className="app-date-input-year"
                  label="Желаемая дата"
                  placeholder="01.01.2022"
                  validations={{ isEmpty: true }}
                  type="date"
                  onInput={(e) => setDate(convertDateToDot(e))}
                  value={convertDate(date)}
                  mode="white"
                  isReq={true}
                  isDisabled={!price}
                />
                <AppSelect
                  optionsList={
                    convertTimes(serviceData?.times)?.length
                      ? convertTimes(serviceData?.times)
                      : { id: "0", name: "Нет вариантов" }
                  }
                  defaultText="--:--"
                  label="Время"
                  validations={{ isEmpty: true }}
                  onInput={(e) => {
                    sessionStorage.setItem("timeNewApp", e);
                    setTime(e);
                  }}
                  selected={currTime}
                  mode="white"
                  isReq={true}
                  isDisabled={!date}
                />
              </div>
            )}
          </div>
        </div>
        <div className="right-section-wrapper">
          <h3 className="side-name">Исполнитель</h3>
          <div className="middle-section-wrapper">
            <ApplicationSelectExecutor
              executor={executor}
              executorId={executorId}
              executorFullData={executor}
              organizationsData={organizationsData}
              setOrganizationId={setOrganizationId}
              setModalView={setModalView}
              organizationId={organizationId}
            />
          </div>
        </div>
      </div>
      <span className="bottom-line" />
      <div className="comment-area">
        <h3 className="side-bottom-name">Комментарий диспетчера</h3>
        <AppTextarea
          placeholder="Добавить комментарий"
          onInput={setDispatcherComment}
          value={dispatcherComment}
        />
      </div>
      <span className="bottom-line"></span>
      <div className="control-area">
        <AppButton
          mode="blue"
          onClick={() => {
            if (!isCreated) {
              sessionStorage.removeItem("currCustomerNewAppId");
              sessionStorage.removeItem("quantityNewApp");
              createOrder();
            } else {
              closeModal();
              sessionStorage.removeItem("currCustomerNewAppId");
              sessionStorage.removeItem("quantityNewApp");
              sessionStorage.removeItem("timeNewApp");
              sessionStorage.removeItem("isOpenedCreateServiceModal");
            }
          }}
          isDisabled={!servicesList?.length}
        >
          {!isCreated ? "Создать заявку" : "Закрыть заявку"}
        </AppButton>
        {!isCreated && (
          <AppButton
            onClick={() => {
              closeModal();
              sessionStorage.removeItem("currCustomerNewAppId");
              sessionStorage.removeItem("quantityNewApp");
              sessionStorage.removeItem("timeNewApp");
              sessionStorage.removeItem("isOpenedCreateServiceModal");
            }}
          >
            Отмена
          </AppButton>
        )}
      </div>
    </div>
  );
};

export default ApplicationNewModalAppMode;
