import { createSlice } from "@reduxjs/toolkit";
import api from "../../api";
import { addSuccess } from "../alerts";
import { APIUrlPrefix } from "../../../utils/constants";
import { errorHandler } from "../../../scripts/commonScripts";

const dispatcherCatalogSlice = createSlice({
  name: "dispatcherCatalog",
  initialState: {
    organizations: [],
    organizationsCount: 0,
    organization: {},
    discountList: [],
    service: {},
    serviceTariffs: [],
    tariffsList: [],
  },
  reducers: {
    setOrganizationList: (state, action) => {
      state.organizations = [...action.payload];
    },
    setOrganizationListCount: (state, action) => {
      state.organizationsCount = action.payload;
    },
    setOrganizationById: (state, action) => {
      state.organization = action.payload;
    },
    setDiscountList: (state, action) => {
      state.discountList = [...action.payload];
    },
    setService: (state, action) => {
      state.service = action.payload;
    },
    setServiceTariffs: (state, action) => {
      state.serviceTariffs = [...action.payload];
    },
    setTariffsList: (state, action) => {
      state.tariffsList = [...action.payload];
    },
  },
});

export const getOrganizationListAsync = (params) => async (dispatch) => {
  await api
    .get(`${APIUrlPrefix}/dispatcher/catalog/organization/`, { params: params })
    .then((r) => {
      dispatch(setOrganizationList(r?.data?.results));
      dispatch(setOrganizationListCount(r?.data?.count));
    })
    .catch((e) => {
      errorHandler({
        error: e,
        dispatch,
        text: "",
        requestName: "getOrganizationListAsync",
      });
    });
};

export const createOrganizationAsync = (data) => async (dispatch) => {
  await api
    .post(`${APIUrlPrefix}/dispatcher/catalog/organization/`, data)
    .then(() => {
      dispatch(addSuccess("Организация успешно добавлена!"));
    })
    .catch((e) => {
      errorHandler({
        error: e,
        dispatch,
        text: "Ошибка создания организации!",
        requestName: "createOrganizationAsync",
      });
    });
};

export const getOrganizationByIdAsync = (id) => async (dispatch) => {
  await api
    .get(`${APIUrlPrefix}/dispatcher/catalog/organization/${id}/`)
    .then((r) => {
      dispatch(setOrganizationById(r?.data));
    })
    .catch((e) => {
      errorHandler({
        error: e,
        dispatch,
        text: "",
        requestName: "getOrganizationByIdAsync",
      });
    });
};

export const editOrganizationByIdAsync = (id, data) => async (dispatch) => {
  await api
    .patch(`${APIUrlPrefix}/dispatcher/catalog/organization/${id}/`, data)
    .then(() => {
      dispatch(addSuccess("Организация успешно отредактирована!"));
    })
    .catch((e) => {
      errorHandler({
        error: e,
        dispatch,
        text: "Ошибка редактирования организации!",
        requestName: "editOrganizationByIdAsync",
      });
    });
};

export const getDiscountListAsync = () => async (dispatch) => {
  await api
    .get(`${APIUrlPrefix}/dispatcher/catalog/discount/`)
    .then((r) => {
      dispatch(setDiscountList(r?.data));
    })
    .catch((e) => {
      errorHandler({
        error: e,
        dispatch,
        text: "",
        requestName: "getDiscountListAsync",
      });
    });
};

export const createServiceAsync = (data) => async (dispatch) => {
  await api
    .post(`${APIUrlPrefix}/dispatcher/catalog/service/`, data)
    .then(() => {
      dispatch(addSuccess("Услуга успешно добавлена!"));
    })
    .catch((e) => {
      errorHandler({
        error: e,
        dispatch,
        text: "Ошибка добавления услуги!",
        requestName: "createServiceAsync",
      });
    });
};

export const getServiceById = (id) => async (dispatch) => {
  await api
    .get(`${APIUrlPrefix}/dispatcher/catalog/service/${id}`)
    .then((r) => {
      dispatch(setService(r?.data));
    })
    .catch((e) => {
      errorHandler({
        error: e,
        dispatch,
        text: "",
        requestName: "getServiceById",
      });
    });
};

export const editServiceByIdAsync = (id, data) => async (dispatch) => {
  await api
    .patch(`${APIUrlPrefix}/dispatcher/catalog/service/${id}/`, data)
    .then(() => {
      dispatch(addSuccess("Услуга успешно изменена!"));
    })
    .catch((e) => {
      errorHandler({
        error: e,
        dispatch,
        text: "Ошибка изменения услуги!",
        requestName: "editServiceByIdAsync",
      });
    });
};

export const getServiceTariffsById = (id) => async (dispatch) => {
  await api
    .get(`${APIUrlPrefix}/dispatcher/catalog/service/${id}/tariffs`)
    .then((r) => {
      dispatch(setServiceTariffs(r?.data));
    })
    .catch((e) => {
      errorHandler({
        error: e,
        dispatch,
        text: "",
        requestName: "getServiceTariffsById",
      });
    });
};

// export const editServiceTariffsById = (id, data) => async (dispatch) => {
//   await api
//     .patch(`${APIUrlPrefix}/dispatcher/catalog/service/${id}/tariffs/`, data)
//     .then(() => {
//       dispatch(addSuccess("Тарифы успешно отредактированы!"));
//     });
// };

export const getTariffsListAsync = (params) => async (dispatch) => {
  await api
    .get(`${APIUrlPrefix}/dispatcher/catalog/tariff/`, { params: params })
    .then((r) => {
      dispatch(setTariffsList(r?.data));
    })
    .catch((e) => {
      errorHandler({
        error: e,
        dispatch,
        text: "",
        requestName: "getTariffsListAsync",
      });
    });
};

export const multipleUpdateTariffListAsync = (data) => async (dispatch) => {
  await api
    .patch(`${APIUrlPrefix}/dispatcher/catalog/tariff/update/`, data)
    .then(() => {
      dispatch(addSuccess("Тарифы успешно отредактированы!"));
    })
    .catch((e) => {
      errorHandler({
        error: e,
        dispatch,
        text: "Ошибка изменения тарифов!",
        requestName: "multipleUpdateTariffListAsync",
      });
    });
};

export const {
  setOrganizationList,
  setOrganizationById,
  setOrganizationListCount,
  setDiscountList,
  setService,
  setServiceTariffs,
  setTariffsList,
} = dispatcherCatalogSlice.actions;
export default dispatcherCatalogSlice.reducer;
