import React, { useEffect } from "react";
import "./styles/AppTextarea.scoped.scss";
import PropTypes from "prop-types";
import { useInput } from "../../../scripts/hooks";
import classNames from "classnames";

const AppTextarea = ({
  label,
  placeholder,
  isDisabled,
  onInput,
  value,
  validations,
  validationText,
  onEnterActionFunc,
  checkValid,
  rows,
  mode,
    isReq,
  ...attr
}) => {
  const valueData = useInput(value, validations);
  const textareaClasses = classNames(
    "app-textarea__textarea",
    textareaClassModes(),
    textareaIsDisabled()
  );

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onEnterActionFunc();
    }
  };

  function textareaClassModes() {
    if (mode?.includes("white")) return "app-textarea__textarea_white";
    else return "";
  }

  function textareaIsDisabled() {
    if (isDisabled) return "app-textarea__textarea_disabled";
  }

  useEffect(() => {
    checkValid(valueData.inputValid);
  });
  function isNeed(req) {
    if (isReq) return "req";
    return "no-req"
  }

  return (
    <div className="app-textarea">
      <div className="label-area">
      <label className="app-textarea__label" htmlFor="textarea">
        {label}
      </label>
        <label className={isNeed()}>*</label>
      </div>
      <textarea
        className={textareaClasses}
        id="textarea"
        placeholder={placeholder}
        disabled={isDisabled}
        rows={rows}
        onKeyDown={handleKeyDown}
        onChange={(e) => {
          valueData.onChange(e.target.value, onInput);
        }}
        onBlur={valueData.onBlur}
        value={valueData.value}
        {...attr}
      />
      {valueData.isDirty && valueData.isEmpty && validations?.isEmpty && (
        <div className="input__error-text">{validationText.isEmpty}</div>
      )}
      {valueData.isDirty &&
        valueData.minLengthError &&
        validations?.minLength && (
          <div className="input__error-text">{validationText.minLength}</div>
        )}
      {valueData.isDirty &&
        valueData.maxLengthError &&
        validations?.maxLength && (
          <div className="input__error-text">{validationText.maxLength}</div>
        )}
    </div>
  );
};

AppTextarea.defaultProps = {
  label: "",
  onInput: () => {},
  rows: 2,
  placeholder: "",
  isDisabled: false,
  isReq: false,
  validations: {},
  checkValid: () => {},
  onEnterActionFunc: () => {},
  mode: "",
  validationText: {
    isEmpty: "Поле не может быть пустым!",
    maxLength: "Слишком длинный текст!",
    minLength: "Текст слишком короткий!",
  },
};

AppTextarea.propTypes = {
  label: PropTypes.string,
  onInput: PropTypes.func,
  rows: PropTypes.number,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  isReq: PropTypes.bool,
  validations: PropTypes.object,
  validationText: PropTypes.object,
  checkValid: PropTypes.func,
  onEnterActionFunc: PropTypes.func,
  mode: PropTypes.string,
};

export default AppTextarea;
