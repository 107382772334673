import React, { useEffect, useState } from "react";
import SvgSelector from "../../../SvgSelector/SvgSelector";
import classNames from "classnames";
import "./styles/NavbarCardTop.scss";
import {
  empListFilters,
  modalKeys,
  RouterPath,
  urListFilters,
} from "../../../../../utils/utils";
import { Mounted } from "../../../../../scripts/hooks";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../../../../../store/reducers/modals";
import { getDispatcherProfileAsync } from "../../../../../store/reducers/dispatcher/profile";

const NavbarCardTop = (option, idx) => {
  let currPath = window.location.pathname;
  const dispatch = useDispatch();
  const { dispatcherProfile } = useSelector((store) => store.dispatcherProfile);
  const { servicesList } = useSelector((store) => store.commonCatalog);

  const cardStyles = classNames("navbar-card-top", cardStyleModes());
  const cardTitleStyles = classNames(
    "navbar-card-top__title",
    cardTitleStyleModes()
  );

  const [empListFilter, setEmpListFilter] = useState(
    localStorage.getItem("empListFilter")
  );
  const [urListFilter, setUrListFilter] = useState(
    localStorage.getItem("urListFilter")
  );
  const [isAdmin, setIsAdmin] = useState(localStorage.getItem('isAdmin'));

  function cardTitleStyleModes() {
    if (![RouterPath.empList, RouterPath.apps].includes(currPath))
      return "navbar-card-top__title_black";
  }

  function cardStyleModes() {
    const cardStyles = {
      [RouterPath.typesOfServices]: "navbar-card-top_type-serv",
      [RouterPath.empList]: "navbar-card-top_emp-list",
      [RouterPath.apps]: "navbar-card-top_apps",
      [RouterPath.urList]: "navbar-card-top_emp-list",
    };
    return cardStyles[currPath];
  }

  function openModal() {
    sessionStorage.removeItem("currCustomerNewAppId");
    sessionStorage.removeItem("quantityNewApp");

    if (currPath === RouterPath.apps) {
      dispatch(
        showModal({
          key: modalKeys.applicationNewModal,
          option: option,
          idx: idx,
          services: servicesList,
        })
      );
    }
    if (currPath === RouterPath.empList) {
      dispatch(
        showModal({
          key: modalKeys.choiceModal,
          option: option,
          idx: idx,
          mode: "edit",
          services: servicesList,
        })
      );
    }
    if (currPath === RouterPath.typesOfServices) {
      dispatch(showModal({ key: modalKeys.choiceServ }));
    }
    if (currPath === RouterPath.urList) {
      dispatch(
        showModal({ key: modalKeys.choiceUrModal, services: servicesList })
      );
    }
  }

  useEffect(() => {
    dispatch(getDispatcherProfileAsync());
    if (dispatcherProfile) {
      setIsAdmin(dispatcherProfile?.is_admin);
    } else {
      setIsAdmin(localStorage.getItem('isAdmin'));
    }
  }, [dispatcherProfile?.is_admin]);

  Mounted(() => {
    if (!localStorage.getItem("urListFilter")) {
      localStorage.setItem("urListFilter", urListFilters.ips);
    }
    if (!localStorage.getItem("empListFilter")) {
      localStorage.setItem("empListFilter", empListFilters.executors);
    } else {
      setInterval(() => {
        setUrListFilter(localStorage.getItem("urListFilter"));
        setEmpListFilter(localStorage.getItem("empListFilter"));
      }, 300);
    }
  });

  return (
    <>
      {currPath === RouterPath.empList &&
        empListFilter === empListFilters.executors && (
          <div className={cardStyles} onClick={openModal}>
            <h2 className={cardTitleStyles}>Добавить сотрудника</h2>
            <div className="navbar-card-top__three-col">
              <SvgSelector id="plus-in-circle" svgColor="white" />
            </div>
          </div>
        )}
      {currPath === RouterPath.empList &&
        empListFilter !== empListFilters.executors && (
          <div className={cardStyles} onClick={openModal}>
            <h2 className={cardTitleStyles}>Добавить сотрудника</h2>
            <div className="navbar-card-top__three-col">
              <SvgSelector id="plus-in-circle" svgColor="white" />
            </div>
          </div>
        )}
      {currPath === RouterPath.apps && (
        <div className={cardStyles} onClick={openModal}>
          <h2 className={cardTitleStyles}>Создать заявку</h2>
          <div className="navbar-card-top__two-col">
            <SvgSelector id="plus-in-circle" svgColor="white" />
          </div>
        </div>
      )}
      {currPath === RouterPath.typesOfServices && isAdmin && (
          <div className={cardStyles} onClick={openModal}>
            <h2 className={cardTitleStyles}>Закрепить услугу</h2>
            <div className="navbar-card-top__two-col">
              <SvgSelector id="plus-in-circle" svgColor="white" />
            </div>
          </div>
        )}
      {currPath === RouterPath.urList && urListFilter === urListFilters.ips && (
        <div className={cardStyles} onClick={openModal}>
          <h2 className={cardTitleStyles}>Добавить предприятие</h2>
          <div className="navbar-card-top__two-col">
            <SvgSelector id="plus-in-circle" svgColor="white" />
          </div>
        </div>
      )}
      {currPath === RouterPath.urList && urListFilter === urListFilters.urs && (
        <div className={cardStyles} onClick={openModal}>
          <h2 className={cardTitleStyles}>Добавить предприятие</h2>
          <div className="navbar-card-top__two-col">
            <SvgSelector id="plus-in-circle" svgColor="white" />
          </div>
        </div>
      )}
    </>
  );
};

export default NavbarCardTop;
