import React, {useEffect, useLayoutEffect, useState} from "react";
import EventEmitter from "../../../utils/EventEmitter";
import { useDispatch, useSelector } from "react-redux";
import {
  createDispatcherAsync,
  createExecutorAsync,
  editDispatcherByIdAsync,
  editExecutorDyIdAsync,
  getDispatchersListAsync,
  getExecutorsListAsync,
} from "../../../store/reducers/dispatcher/account";
import AppMainTable from "../../layout/AppMainTable/AppMainTable";
import { empListFilters, RouterPath } from "../../../utils/utils";
import { getOrganizationListAsync } from "../../../store/reducers/dispatcher/catalog";
import {empListPerPage, updatePagesPeriod} from "../../../utils/constants";
import {getDictionariesAsync, getServicesListAsync} from "../../../store/reducers/common/catalog";
import {getDispatcherProfileAsync} from "../../../store/reducers/dispatcher/profile";

const EmpList = () => {
  const currLocation = window.location.pathname;
  const dispatch = useDispatch();

  const { dispatcherListHeaders, executorListHeaders } = useSelector((store) => store.tables);
  const { executors, dispatchers, dispatchersCount, executorsCount } = useSelector((store) => store.dispatcherAccount);
  const { servicesList } = useSelector((store) => store.commonCatalog);
  const { dispatcherProfile } = useSelector((store) => store.dispatcherProfile);

  const [executorsListData, setExecutorsListData] = useState(executors);
  const [executorsListDataCount, setExecutorsListDataCount] = useState(
    executors?.length
  );
  const [dispatcherListData, setDispatcherListData] = useState(dispatchers);
  const [dispatcherListDataCount, setDispatcherListDataCount] = useState(
    dispatchers.length
  );
  const [servicesListData, setServicesListData] = useState(servicesList);

  const [dataTermDispatcher, setDataTermDispatcher] = useState({
    page: 1,
    page_size: empListPerPage,
  });

  const [dataTermExecutor, setDataTermExecutor] = useState({
    page: 1,
    page_size: empListPerPage,
  });

  const [executorsServices, setExecutorsServices] = useState("");
  const [orderingData, setOrderingData] = useState("");
  const [dispatcherCountData, setDispatcherCountData] =
    useState(dispatchersCount);
  const [executorsCountData, setExecutorsCountData] = useState(executorsCount);
  const [isAdmin, setIsAdmin] = useState(dispatcherProfile?.is_admin);
  const [q, setQ] = useState('');

  const getExecutorList = async ({ searchData }) => {
    const params = { ...dataTermExecutor };
    if (!!searchData?.length) params.q = searchData;
    if (!!executorsServices?.length) params.services = executorsServices;
    if (!!orderingData?.length) params.ordering = orderingData;
    dispatch(getExecutorsListAsync(params));
  };

  const getDispatcherList = ({ searchData }) => {
    const params = { ...dataTermDispatcher };
    params.district = '';
    if (!!searchData?.length) params.q = searchData;
    if (!!orderingData?.length) params.ordering = orderingData;
    dispatch(getDispatchersListAsync(params));
  };

  useLayoutEffect(() => {
    dispatch(getDictionariesAsync());
    dispatch(getDispatcherProfileAsync()).then(() => {
      const searchData = {
        searchData: ''
      };
      if (dispatcherProfile?.district?.id) searchData.district = dispatcherProfile?.district?.id
        getExecutorList(searchData);
        setExecutorsListData(executors);
        if (isAdmin) {
          getDispatcherList(searchData);
          setDispatcherListData(dispatchers);
        }
    })
  }, [])

  useLayoutEffect(() => {
    dispatch(getServicesListAsync({ page: 1, page_size: 15000 }));
    dispatch(getDispatcherProfileAsync());
    if (dispatcherProfile) {
      localStorage.setItem('isAdmin', dispatcherProfile?.is_admin);
    }
    setTimeout(() => {
      setServicesListData(servicesList);
      setIsAdmin(dispatcherProfile?.is_admin);
    }, 0);
      localStorage.setItem("empListFilter", empListFilters.executors);
    if (!!isAdmin) {
        getExecutorList({ searchData: '' });
        setExecutorsListData(executors);
        getDispatcherList({searchData: ''});
        setDispatcherListData(dispatchers);
    }
    if (!isAdmin && !!dispatcherProfile?.district?.id?.toString()?.length) {
      getExecutorList({ searchData: '', district: dispatcherProfile?.district?.id }).then(() => {});
      setExecutorsListData(executors);
    }
  }, [dispatcherProfile?.district?.id, dispatcherProfile?.district?.name])

  useEffect(() => {
    const updatePage = setInterval(() => {
      if (currLocation === RouterPath.empList) {
        dispatch(getOrganizationListAsync());
          if (!isAdmin && !!dispatcherProfile?.district?.id?.toString()?.length) {
            getExecutorList({ searchData: q, district: dispatcherProfile?.district?.id }).then(() => {});
            setExecutorsListData(executors);
          }
          if (!!isAdmin) {
            getDispatcherList({ searchData: q });
            setDispatcherListData(dispatchers);
          }
      }
      setIsAdmin(dispatcherProfile?.is_admin);
    }, updatePagesPeriod);
    return () => clearInterval(updatePage);
  }, [currLocation]);

  useEffect(() => {
    setTimeout(() => {
      setDispatcherCountData(dispatchersCount);
    }, 0);
  }, [dispatchersCount]);

  useEffect(() => {
    setTimeout(() => {
      setExecutorsCountData(executorsCount);
    }, 0);
  }, [executorsCount]);

  useEffect(() => {
    setTimeout(() => {
      setDispatcherListData(dispatchers);
      setDispatcherListDataCount(dispatchers?.length);
    }, 0);
  }, [dispatchers]);

  useEffect(() => {
    setTimeout(() => {
      setExecutorsListData(executors);
      setExecutorsListDataCount(executors?.length);
    }, 0);
  }, [executors]);

  useEffect(() => {

  }, [setDispatcherListData])

  useEffect(() => {

  }, [setExecutorsListData])

  useEffect(() => {
    function getData({ page, services, ordering }) {
      if (localStorage.getItem("empListFilter") === empListFilters.executors) {
        setDataTermExecutor({
          ...dataTermExecutor,
          page,
          services: services ? services : executorsServices,
          ordering: ordering ? ordering : orderingData,
        });
        dispatch(
          getExecutorsListAsync({
            ...dataTermExecutor,
            page,
            services: services ? services : executorsServices,
            ordering: ordering ? ordering : orderingData,
          })
        );
      } else {
        setDataTermDispatcher({
          ...dataTermDispatcher,
          page,
          ordering: ordering ? ordering : orderingData,
        });
        dispatch(
          getDispatchersListAsync({
            ...dataTermDispatcher,
            page,
            ordering: ordering ? ordering : orderingData,
          })
        );
      }
    }

    const addNewDispatcher = (eventData) => {
      const formData = new FormData();
      formData.append("username", eventData.username);
      formData.append(
        "district",
        typeof eventData.district === "object"
          ? eventData.district.id
          : eventData?.district
      );
      formData.append("fio", eventData.fio);
      formData.append("email", eventData.email);
      formData.append("phone", eventData.phone);
      formData.append("photo", eventData.photo);
      dispatch(createDispatcherAsync(formData));
      setDispatcherListData((prevValue) => [...prevValue, eventData]);
      setDispatcherListDataCount((prevValue) => prevValue + 1);
      EventEmitter.emit("dispatcherCount", dispatcherListDataCount);
    };

    const editDispatcherById = (eventData) => {
      const formData = new FormData();
      if (eventData?.active === false) {
        formData.append("active", eventData.active);
      }
      formData.append("username", eventData.username);
      formData.append("fio", eventData.fio);
      formData.append("email", eventData.email);
      formData.append("phone", eventData.phone);
      if (eventData.district)
        formData.append(
          "district",
          typeof eventData.district === "object"
            ? eventData.district.id
            : eventData?.district
        );
      if (typeof eventData.photo === "object")
        formData.append("photo", eventData.photo);
      dispatch(editDispatcherByIdAsync(eventData.id, formData));
      setDispatcherListData([
        ...dispatcherListData.filter((item) => item.id !== eventData.id),
        eventData,
      ]);
    };

    const addNewExecutor = (eventData) => {
      const formData = new FormData();
      formData.append("fio", eventData.fio);
      for (const service of eventData.services)
        formData.append("services", service);
      formData.append("phone", eventData.phone);
      formData.append("email", eventData.email);
      formData.append("photo", eventData.photo);
      formData.append("organization", eventData.organization);
      formData.append("address", eventData.address);
      formData.append("type", eventData.type);
      formData.append("district", eventData.district);
      dispatch(createExecutorAsync(formData));
      setExecutorsListData((prevValue) => [...prevValue, eventData]);
      setExecutorsListDataCount((prevValue) => prevValue + 1);
      getExecutorList({ searchData: q, district: dispatcherProfile?.district?.id }).then(r => {})
      EventEmitter.emit("executorCount", executorsListDataCount);
    };

    const editExecutorById = (eventData) => {
      const formData = new FormData();
      formData.append("fio", eventData?.fio);
      console.log('eventData?.active', eventData?.active)
      if (eventData?.active === false) {
        formData.append("active", eventData.active);
      }
      for (const service of eventData?.services)
        formData.append("services", service);
      formData.append("phone", eventData?.phone);
      formData.append("email", eventData?.email);
      formData.append("organization", eventData?.organization);
      formData.append("address", eventData?.address);
      formData.append("type", eventData?.type);
      formData.append("district", eventData?.district);
      if (typeof eventData?.photo === "object")
        formData.append("photo", eventData?.photo);
      dispatch(editExecutorDyIdAsync(eventData?.id, formData));
      setExecutorsListData([
        ...executorsListData.filter((item) => item?.id !== eventData?.id),
        eventData,
      ]);
    };

    const dataTermExecutorListener = EventEmitter.addListener(
      "dataTermExecutor",
      () => {
        if (!!isAdmin) {
          getExecutorList({ serviceData: q }).then(() => {});
        }
        if (!isAdmin && !!dispatcherProfile?.district?.id) {
          getExecutorList({ searchData: q, district: dispatcherProfile?.district?.id }).then(() => {});
        }
      }
    );

    const dataTermDispatcherListener = EventEmitter.addListener(
      "dataTermDispatcher",
      () => getDispatcherList({ searchData: q })
    );

    const searchListener = EventEmitter.addListener("search", (e) => {
      if (currLocation === RouterPath.empList) {
        setQ(e);
        if (
          localStorage.getItem("empListFilter") === empListFilters.dispatchers
        ) {
          getDispatcherList({ searchData: e });
        }
        if (
          localStorage.getItem("empListFilter") === empListFilters.executors
        ) {
          if (!!isAdmin) {
            getExecutorList({ serviceData: q }).then(() => {});
          }
          if (!isAdmin && !!dispatcherProfile?.district?.id) {
            getExecutorList({ searchData: e, district: dispatcherProfile?.district?.id });
          }
        }
      }
    });

    const addNewDispatcherListener = EventEmitter.addListener(
      "addNewDispatcher",
      (e) => addNewDispatcher(e)
    );
    const editDispatcherListener = EventEmitter.addListener(
      "editDispatcher",
      (e) => editDispatcherById(e)
    );

    const addNewExecutorListener = EventEmitter.addListener(
      "addNewExecutor",
      (e) => addNewExecutor(e)
    );

    const filterModalEventListener = EventEmitter.addListener(
      "filterModalEvent",
      async (eventData) => {
        let servicesString = "";
        if (!!eventData?.servicesData?.length)
          servicesString = eventData?.servicesData.join(",");
        await setOrderingData(eventData?.ordering);
        await setExecutorsServices(servicesString);
        // TODO: Добавить если будет фильтр по статусам исполнителя
        // await setExecutorsStatuses()
        getData({ services: servicesString, ordering: eventData?.ordering });
      }
    );

    const pageListener = EventEmitter.addListener("page", (page) =>
      getData({ page })
    );

    const editExecutorListener = EventEmitter.addListener("editExecutor", (e) =>
      editExecutorById(e)
    );

    return () => {
      dataTermExecutorListener.remove();
      dataTermDispatcherListener.remove();
      searchListener.remove();
      addNewDispatcherListener.remove();
      editDispatcherListener.remove();
      addNewExecutorListener.remove();
      editExecutorListener.remove();
      pageListener.remove();
      filterModalEventListener.remove();
    };
  });

  return (
    <>
      <AppMainTable
        title={
          localStorage.getItem("empListFilter") === empListFilters.executors
            ? "Список сотрудников"
            : "Список диспетчеров"
        }
        search={
          localStorage.getItem("empListFilter") === empListFilters.executors
            ? "Найти исполнителя"
            : "Найти диспетчера"
        }
        itemList={
          localStorage.getItem("empListFilter") === empListFilters.executors
            ? executorsListData
            : dispatcherListData
        }
        headerList={
          localStorage.getItem("empListFilter") === empListFilters.executors
            ? executorListHeaders
            : dispatcherListHeaders
        }
        itemListTotalCount={
          localStorage.getItem("empListFilter") === empListFilters.executors
            ? executorsCountData
            : dispatcherCountData
        }
        servicesList={servicesListData}
        perPage={empListPerPage}
        mode="empListTable"
      />
    </>
  );
};

export default EmpList;
